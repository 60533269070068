<script>
    const GLOW_FILTER_STYLES = "filter:url(#fglow);";
    let step = 1;
</script>

<style>
    .journey-svg {
        transform: scale(1.5);
    }
</style>

<svg version="1.1" class="journey-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1220.65 472.55" style="enable-background:new 0 0 1220.65 472.55;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
    .st1{font-family:'Rajdhani',sans-serif;font-weight:bold;}
    .st2{font-size:120px;}
    .st3{font-size:50px;}
</style>
    <defs>
    <filter id="fglow" x="-200%" y="-200%" width="500%" height="500%">
        <feDropShadow dx="0" dy="0" stdDeviation="15" flood-color="skyblue"/>
<!--        <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />-->
<!--        <feColorMatrix result="matrixOut" in="offOut" type="matrix" values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0" />-->
<!--        <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="10" />-->
<!--        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />-->
    </filter>
  </defs>
	<g id="step3" style="{step === 3 ? GLOW_FILTER_STYLES : ''}">
		<g id="step2" style="{step === 2 ? GLOW_FILTER_STYLES : ''}">
			<path class="st0" d="M592.58,276.65c-36.15-8.21-63.22-40.59-63.22-79.18s27.07-70.97,63.22-79.18v-15.35
				c-44.54,8.43-78.22,47.54-78.22,94.53s33.68,86.1,78.22,94.53V276.65z"/>
            <path class="st0" d="M624.67,102.28v15.22c38.09,6.69,67.13,39.99,67.13,79.97s-29.04,73.28-67.13,79.97v15.22
				c46.46-6.82,82.13-46.83,82.13-95.19S671.13,109.1,624.67,102.28z"/>
            <text transform="matrix(1 0 0 1 581.8254 237.9489)" class="st0 st1 st2">2</text>
            <text transform="matrix(1 0 0 1 569.8552 352.9722)" class="st0 st1 st3">PRO</text>
            <g id="step1" style="{step === 1 ? GLOW_FILTER_STYLES : ''}">
				<path class="st0" d="M342.92,276.65c-36.15-8.21-63.22-40.59-63.22-79.18s27.07-70.97,63.22-79.18v-15.35
					c-44.54,8.43-78.22,47.54-78.22,94.53s33.68,86.1,78.22,94.53V276.65z"/>
                <path class="st0" d="M375.01,102.28v15.22c38.09,6.69,67.13,39.99,67.13,79.97s-29.04,73.28-67.13,79.97v15.22
					c46.46-6.82,82.13-46.83,82.13-95.19S421.47,109.1,375.01,102.28z"/>
                <text transform="matrix(1 0 0 1 338.8823 237.9484)" class="st0 st1 st2">1</text>
                <text transform="matrix(1 0 0 1 264.1985 352.972)" class="st0 st1 st3">AMATEUR</text>
			</g>
            <rect id="path12" x="444.46" y="187.01" class="st0" width="83.06" height="20.91"/>
		</g>
        <rect id="path23" x="693.63" y="187.01" class="st0" width="83.06" height="20.91"/>
        <path class="st0" d="M842.24,276.65c-36.15-8.21-63.22-40.59-63.22-79.18s27.07-70.97,63.22-79.18v-15.35
			c-44.54,8.43-78.22,47.54-78.22,94.53s33.68,86.1,78.22,94.53V276.65z"/>
        <path class="st0" d="M874.32,102.28v15.22c38.09,6.69,67.13,39.99,67.13,79.97s-29.04,73.28-67.13,79.97v15.22
			c46.46-6.82,82.13-46.83,82.13-95.19S920.79,109.1,874.32,102.28z"/>
        <text transform="matrix(1 0 0 1 830.6685 237.9483)" class="st0 st1 st2">3</text>
        <text transform="matrix(1 0 0 1 784.7884 352.9722)" class="st0 st1 st3">EXPERT</text>
	</g>
</svg>


<script>
    import SimpleMarkdown from 'simple-markdown';
    import { init as initDiscordPreview, parse } from "../../util/discord-preview";

    let discordShadow = null;
    let discordShadowElement = null;
    $: if(discordShadowElement != null) {
        prepareDiscordShadow();
    }

    async function prepareDiscordShadow() {
        await initDiscordPreview();

        let discordCss = await(await fetch('/data/discord-css.css')).text();

        let discordCssElement = document.createElement('style');
        discordCssElement.textContent = discordCss;

        discordShadow = discordShadowElement.attachShadow({mode: 'open'});
        discordShadow.innerHTML = `
<div class='w-100 h-100 overflow-auto pa2 discord-view'>
    <div class='flex-vertical whitney theme-dark'>
        <div class='chat flex-vertical flex-spacer'>
            <div class='content flex-spacer flex-horizontal'>
                <div class='flex-spacer flex-vertical messages-wrapper'>
                    <div class='scroller-wrap'>
                        <div class='scroller messages'>
<div class="message-group hide-overflow ">
    <div class="avatar-large animate" style="background-image: url(&quot;https://cdn.discordapp.com/avatars/587423121842372637/39dd3eceeb1fb8fc5452adf9faeafff4.webp?size=128&quot;);"></div>
    <div class="comment">
        <div class="message first">
            <h2 style="line-height: 16px;"><span class="username-wrapper v-btm"><strong class="user-name">OEF</strong><span class="bot-tag">BOT</span></span><span class="highlight-separator"> - </span><span class="timestamp">Today at 10:43 PM</span></h2>
            <div class="message-text">
              <div id="inner" class="markup">
              </div>
            </div>
        </div>
    </div>
</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        `;
        discordShadow.appendChild(discordCssElement);
    }

    let emailShadow = null;
    let emailShadowElement = null;
    let emailPreviewElement = null;
    let emailBodyElement = null;
    let emailTitleElement = null;
    $: if(emailShadowElement != null) {
        prepareEmailShadow();
    }
    async function prepareEmailShadow() {
        let emailShadowBase = await (await fetch('/data/broadcast-email-base.html')).text();

        emailShadow = emailShadowElement.attachShadow({mode: 'open'});
        emailShadow.innerHTML = emailShadowBase;

        emailPreviewElement = emailShadow.querySelector(".bc-preview");
        emailBodyElement = emailShadow.querySelector(".bc-body");
        emailTitleElement = emailShadow.querySelector(".bc-title");
    }

    let message = "";
    let emailSubject = "";
    let emailPreview = "";
    let emailTitle = "";

    let emailMdRules = {
        ...SimpleMarkdown.defaultRules,
        paragraph: {
            ...SimpleMarkdown.defaultRules.paragraph,
            html(node, output) {
                return `<p>${output(node.content)}</p>`
            }
        }
    };
    let emailMdParser = SimpleMarkdown.parserFor(emailMdRules);
    let emailMdOutput = SimpleMarkdown.htmlFor(SimpleMarkdown.ruleOutput(emailMdRules, 'html'));
    $: {
        if (discordShadow != null) {
            let parsed = parse(message);
            let shadowInner = discordShadow.getElementById("inner");
            shadowInner.innerHTML = parsed;
        }
        if(emailShadow != null && emailPreviewElement != null && emailBodyElement != null && emailTitleElement != null) {
            let parsed = emailMdOutput(emailMdParser(message));
            emailPreviewElement.textContent = emailPreview;
            emailBodyElement.innerHTML = parsed;
            emailTitleElement.textContent = emailTitle;
        }
    }

    function download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    function downloadBroadcast() {
        const compiled = {
            message,
            emailSubject,
            emailPreview,
            emailTitle,
            compiledEmail: emailShadow.innerHTML
        };

        download("message.oefb", JSON.stringify(compiled));
    }

    function importBroadcast(event) {
        const target = event.target;
        const reader = new FileReader();

        reader.onload = function(event) {
            const json = JSON.parse(event.target.result);
            message = json.message;
            emailSubject = json.emailSubject;
            emailPreview = json.emailPreview;
            emailTitle = json.emailTitle;
            target.value = null;
        };

        reader.readAsText(target.files[0]);
    }
</script>
<style>
    .discord-message {
    }
    .wrapper {
        margin-bottom: 2em;
    }
</style>
<div class="ui container wrapper">
    <h1 class="ui inverted header">Broadcast creator</h1>
    <div class="ui inverted form">
        <div class="field">
            <label>Email subject</label>
            <input type="text" bind:value={emailSubject}>
        </div>
        <div class="field">
            <label>Email preview message</label>
            <input type="text" bind:value={emailPreview}>
        </div>
        <div class="field">
            <label>Email title</label>
            <input type="text" bind:value={emailTitle}>
        </div>
        <div class="field">
            <label>Message</label>
            <textarea rows="20" bind:value={message}></textarea>
        </div>
        <div class="field">
            <label>Discord message preview</label>
            <div class="discord-message" bind:this={discordShadowElement}>
            </div>
        </div>
        <div class="field">
            <label>Email message preview</label>
            <div class="email-message" bind:this={emailShadowElement}>
            </div>
        </div>
        <div class="field">
            <label>Import broadcast (.oefb)</label>
            <input class="ui secondary button" type="file" on:change={importBroadcast}/>
        </div>
        <div class="ui primary button" on:click={downloadBroadcast}>Download broadcast</div>
    </div>
</div>
<script>
    export let title;
    export let backgroundImage;
</script>

<style>
    .page-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .page-header {
        background-position: center;
        background-size: cover;
        padding: 5em 0;
    }
    .page-body {
        flex-grow: 1;
    }
</style>

<div class="page-wrapper">
    <div class="page-header"
         style="background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url({backgroundImage})">
        <div class="ui text container">
            <h1 class="ui inverted huge header centered">
                {title}
            </h1>
        </div>
    </div>
    <div class="page-body">
        <slot></slot>
    </div>
</div>
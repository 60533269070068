<script>
    import LinkSteamModal from "./LinkSteamModal.svelte";
            import ChangeSchoolEmailModal from "./ChangeSchoolEmailModal.svelte";
    import {slide, scale} from "svelte/transition";
    import {apiGet, apiPost} from "../../api";
    import {tick} from "svelte";
    import {navigate} from "svelte-routing";
    import queryString from "query-string";

    let loading = false;
    //let initialLoadCountdown = 3;
    let initialLoadCountdown = 2;

    // let schoolDropdown = null;
    let gradeDropdown = null;

    // let schools = [];
    let grades = [];
    let profile = {
        valid: true,
        requiresOrgAuth: false,
        // name: "",
        // school: "",
        grade: "",
        email: "",
        schoolEmail: ""
    };

    let query = queryString.parse(window.location.search);

    let schoolEmailModal = null;
    let linkSteamModal = null;

    function showProfileError(title, error) {
        jQuery('body').toast({
            class: 'error',
            title: 'Failed to load profile: ' + title,
            message: error
        })
    }

    /*async function getSchools() {
        try {
            let response = await apiGet('/profile/schools');
            if(!response.success) {
                showProfileError('Could not fetch school list!', response.error);
                return;
            }

            schools = response.data;
        } finally {
            initialLoadCountdown--;
            await setupForm();
        }
    }*/

    async function getGrades() {
        try {
            let response = await apiGet('/profile/grades');
            if(!response.success) {
                showProfileError('Could not fetch grade list!', response.error);
                return;
            }

            grades = response.data;
        } finally {
            initialLoadCountdown--;
            await setupForm();
        }
    }

    async function getProfile() {
        loading = true;
        try {
            let response = await apiGet('/profile');
            if(!response.success) {
                showProfileError('Could not fetch profile!', response.error);
                return;
            }

            profile = response.data;

            /*if(profile.valid && query.redirect != null) {
                window.location.href = query.redirect;
            } else if(profile.requiresOrgAuth) {
                navigate(`/auth/verify-email?next=${encodeURIComponent(window.location.href)}`);
            }*/
        } finally {
            initialLoadCountdown--;
            await setupForm();
            loading = false;
        }
    }

    async function setupForm() {
        if(initialLoadCountdown === 0) {
            await tick();
            updateDropdowns();
        }
    }

    function updateDropdowns() {
        /*if(schoolDropdown != null) {
            jQuery(schoolDropdown).dropdown({
                fullTextSearch: true
            });
        }*/
        if(gradeDropdown != null) {
            jQuery(gradeDropdown).dropdown();
        }
    }

    async function saveProfile() {
        loading = true;
        try {
            let response = await apiPost('/profile', {
                // school: profile.school,
                grade: profile.grade,
                email: profile.email
            });
            if(!response.success) {
                jQuery('body').toast({
                    class: 'error',
                    title: 'Failed to save profile',
                    message: response.error
                });
                return;
            }

            jQuery('body').toast({
                class: 'success',
                title: 'Profile saved',
                message: 'Successfully saved your profile!'
            });

            await getProfile();
            updateDropdowns();
        } finally {
            loading = false;
        }
    }

    function showSchoolEmailModal() {
        if(schoolEmailModal != null) schoolEmailModal.show();
    }

    function fieldIsValid(contents) {
        if(contents == null) return false;
        return contents.trim().length > 0;
    }

    $: if(location.hash === "#link-steam" && linkSteamModal != null) {
        linkSteamModal.show();
    }

    // getSchools();
    getGrades();
    getProfile();
</script>

<style>
    .join-discord.message {
        color: white;
        background-color: #7289DA;
    }
    .join-discord-warning {
        background-color: white;
        color: red;
    }
</style>

<div class="ui container">
    <h1 class="ui inverted header">Profile</h1>
    {#if initialLoadCountdown <= 0}
        {#if profile.valid}
            {#if profile.inDiscordServer}
                <div class="ui icon positive message" transition:slide|local>
                    <i class="icon fas fa-check"></i>
                    <div class="content">
                        <div class="header">
                            Profile complete
                        </div>
                        <p>Your profile is complete, make sure you are in a team before registration closes!</p>
                    </div>
                </div>
            {:else}
                <div class="ui icon inverted join-discord message" transition:slide|local>
                    <i class="discord icon"></i>
                    <div class="content">
                        <div class="header">
                            Join Discord
                        </div>
                        <p>
                            Your profile is complete but you aren't in our Discord server!
                            <b class="join-discord-warning">You cannot participate in the tournament until you join our Discord server</b>
                            as we use Discord to communicate with you during the tournament.
                        </p>

                        <a class="ui inverted button" href="/discord" target="_blank">
                            Join Discord server
                        </a>
                    </div>
                </div>
            {/if}
        {:else}
            <div class="ui icon negative message" transition:slide|local>
                <i class="icon fas fa-exclamation-triangle"></i>
                <div class="content">
                    <div class="header">
                        Profile incomplete
                    </div>
                    <p>Your profile is incomplete, you must complete it by filling in all the fields below to be entered into the tournament.</p>
                </div>
            </div>
        {/if}
    {/if}

    <div class="ui inverted form" class:loading={loading || initialLoadCountdown > 0}>
        <!--<div class="field">
            <label>Full Name</label>
            <input type="text" disabled value={profile.name}>
        </div>-->
        <div class="field" data-tooltip="This is how we will contact you.">
            <label>
                {#if !loading && !fieldIsValid(profile.email)}
                    <div class="ui red horizontal label" in:scale|local>REQUIRED</div>
                {/if}
                Personal email
            </label>
            <input type="email" placeholder="me@example.com" bind:value={profile.email}>
        </div>
        <div class="field" class:disabled={profile.schoolEmail.length > 0}>
            <label>
                {#if !loading && !fieldIsValid(profile.schoolEmail)}
                    <div class="ui red horizontal label" in:scale|local>REQUIRED</div>
                {/if}
                School email (used to verify you are in high school)
            </label>
            <div class="ui action input" on:click={showSchoolEmailModal}>
                <input type="text" placeholder="Click the 'Edit' button to edit this field" bind:value={profile.schoolEmail} readonly>
                <button class="ui teal right labeled icon button">
                    <i class="edit icon"></i>
                    Edit
                </button>
            </div>
        </div>
        <div class="field">
            <label>
                {#if !loading && !fieldIsValid(profile.grade)}
                    <div class="ui red horizontal label" in:scale|local>REQUIRED</div>
                {/if}
                Grade (or grade going into)
            </label>
            <div class="ui inverted selection dropdown" bind:this={gradeDropdown}>
                <input type="hidden"
                       on:change={e => (profile.grade = e.target.value)}
                       value={profile.grade}>
                <i class="dropdown icon"></i>
                <div class="default text">Grade</div>
                <div class="menu">
                    {#each grades as grade}
                        <div class="item" data-value={grade.code}>{grade.name}</div>
                    {/each}
                </div>
            </div>
        </div>
        <!--<div class="field">
            <label>School</label>
            <div class="ui search inverted selection dropdown" bind:this={schoolDropdown}>
                <input type="hidden"
                       on:change={e => (profile.school = e.target.value)}
                       value={profile.school}>
                <i class="dropdown icon"></i>
                <div class="default text">School</div>
                <div class="menu">
                    {#each schools as school}
                        <div class="item" data-value={school.code}>{school.name}</div>
                    {/each}
                </div>
            </div>
        </div>-->
        <div class="field">
            <div class="ui black message floating">
                <div class="header" style="padding-bottom: 1em;">
                    Summarized OEF Terms of Play
                </div>

                <p>
                    <b>You registered? Then show up.</b>
                    If you registered for a tournament, then you MUST show up in the time period stated. Read the time of the tournament when registering for an event and make sure you can allocate a specific amount of time. If one or more of your teammates do not show up, your team will be forced to forfeit the match and will be disqualified for that specific tournament. These rules apply to all competitors and there will be no exceptions at all times. This is to ensure the quality of the tournament.
                </p>

                <p>
                    <b>No client-side cheats/hacks.</b>
                    Very straightforward but enforced HEAVILY THROUGHOUT THE TOURNAMENTS. Caught once means perma bans for your whole team. We might even sprinkle some extra punishments as well. Just don’t cheat. Thanks.
                </p>

                <p>
                    <b>Only high school students from Ontario.</b>
                    Yep. That means we don’t want any of your online friends from around the world. No people from our neighbours down south, no one from across the oceans, just Ontario, Canada. Also, they must be in the high school age range. No kids, no adults, just us. The high schoolers.
                </p>

                <p>
                    <b>It is imperative that all students in OEF must maintain a quasi-amateur status to qualify for league play. Players considered to play on a professional level are not eligible to play in any OEF competition.</b>
                    It is important to note that being a qualified amateur by OEF does not entitle you to be qualified as an amateur by other organizations. If planning to play collegiate esports a player must ensure that the respective organization’s amateurism rules are followed.
                </p>

                <p>This is not the complete set of rules, the full set of rules can be found <a href="https://docs.google.com/document/d/1-zHFtVZyi-shHO5wvCr8gVFirziZ5Ll5dQtl66IBMAM/edit" target="_blank">here</a>.</p>
            </div>
        </div>
        <button class="ui primary button" on:click={saveProfile}>Save</button>
    </div>

    <ChangeSchoolEmailModal bind:this={schoolEmailModal}/>

    <LinkSteamModal bind:this={linkSteamModal}/>
</div>
<script>
    import { link } from "svelte-routing";
    export let title;
    export let showExtra = true;
</script>

<style>
    .grid.main {
        height: 100%;
    }

    .column {
        max-width: 450px;
    }

    .login-logo {
        width: 300px;
        margin-bottom: 30px;
    }
</style>

<div class="ui middle aligned center aligned grid main">
    <div class="column">
        <a href="/" use:link>
            <img src="/images/LOGO-C-TRANS.min.png" class="image login-logo" alt="WREL Logo">
        </a>

        <div class="ui stacked segments">
            <div class="ui inverted segment">
                <h1 class="ui">{title}</h1>
            </div>
            <div class="ui inverted segment">
                <slot name="content"></slot>
            </div>
        </div>

        {#if showExtra}
            <div class="ui inverted message">
                <slot name="extra"></slot>
            </div>
        {/if}
    </div>
</div>

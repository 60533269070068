<script>
    import Modal from "../../components/fomantic/Modal.svelte";
            import TeamSchedulerModal from "./TeamSchedulerModal.svelte";
    import { gameTypes, tournamentTypes } from "./teams";
    import MemberList from "./MemberList.svelte";
    import { navigate, Link } from "svelte-routing";
    import { apiPost, apiGet } from "../../api";
    import { slide } from "svelte/transition";
    import { tick } from "svelte";

    const INVITE_BASE_URL = "https://ontarioesports.org/join?invite=";

    export let ulid = null;

    let adding = ulid == null || ulid === '';

    let gameDropdown;
    $: if(gameDropdown != null) {
        jQuery(gameDropdown).dropdown();
    }

    let teamName = "";
    let tournamentId = null;
    let members = [];
    let subs = [];
    let inviteUrl = "";
    let subInviteUrl = "";
    let revision = 0;
    let onWaitlist = false;

    let loading = false;
    let initialLoadDone = false;

    let dirty = false;

    async function saveChanges() {
        loading = true;
        try {
            if (adding) {
                await addTeam();
            } else {
                await saveTeam();
            }
        } finally {
            loading = false;
        }
    }

    async function addTeam() {
        let response = await apiPost("/teams/add", {
            name: teamName,
            tournament: tournamentId
        });

        if (!response.success) {
            jQuery('body').toast({
                class: 'error',
                title: 'Failed to add team',
                message: response.error
            });
        } else {
            navigate(`/panel/teams/edit/${response.data.ulid}`)
        }
    }

    async function saveTeam() {
        let response = await apiPost(`/teams/${ulid}`, {
            name: teamName,
            revision: revision,
            members: members.map(m => ({id: m.id, owner: m.owner})),
            subs: subs.map(m => ({id: m.id, owner: m.owner}))
        });

        if (!response.success) {
            jQuery('body').toast({
                class: 'error',
                title: 'Failed to save team',
                message: response.error
            });
        } else {
            jQuery('body').toast({
                class: 'success',
                title: 'Team updated',
                message: "Successfully saved your changes!"
            });
            navigate("/panel/teams")
        }
    }

    function onSwapRole(newRole, member) {
        deleteMember(member);
        if(newRole === 'member') {
            members.push(member);
            // Force re-render
            members = members;
        } else {
            subs.push(member);
            // Force re-render
            subs = subs;
        }
        dirty = true;
    }

    function deleteMember(member) {
        let memberIndex = members.findIndex(m => m.id === member.id);
        if(memberIndex >= 0) members.splice(memberIndex, 1);
        let subIndex = subs.findIndex(m => m.id === member.id);
        if(subIndex >= 0) subs.splice(subIndex, 1);

        // Force re-render of members + subs
        members = members;
        subs = subs;
        dirty = true;
    }

    function transferOwnership(member) {
        members.find(m => m.owner).owner = false;
        member.owner = true;

        // Force re-render members
        members = members;
        dirty = true;
    }

    async function loadTeamInfo() {
        loading = true;
        try {
            let response = await apiGet(`/teams/${ulid}`);

            if(!response.success) {
                jQuery('body').toast({
                    class: 'error',
                    title: 'Failed to get team information',
                    message: response.error
                });
                navigate(`/panel/teams`);
                return;
            }

            let data = response.data;
            teamName = data.team.name;
            revision = data.team.revision;
            tournamentId = data.team.tournament;
            members = data.members;
            subs = data.substitutes;
            onWaitlist = data.onWaitlist;
            inviteUrl = INVITE_BASE_URL + data.team.memberJoinToken;
            subInviteUrl = INVITE_BASE_URL + data.team.substituteJoinToken;

            // Refresh dropdown
            await tick();
            if(gameDropdown != null) jQuery(gameDropdown).dropdown();
        } finally {
            loading = false;
            initialLoadDone = true;
        }
    }

    let inviteUrlInput;
    let subInviteUrlInput;
    async function copyText(input) {
        if(input != null) {
            input.select();
        }
        document.execCommand("copy");
    }

    if(!adding) loadTeamInfo();

    let teamSchedulerModal = null;
    function showTeamScheduler() {
        if(teamSchedulerModal != null) teamSchedulerModal.show();
    }

    $: if(teamSchedulerModal != null && teamName != "" && location.hash === "#scheduler") {
        tick().then(showTeamScheduler);
        location.hash = "";
    }

    let selectedGame = null;
    let selectedTournament = null;
    $: if($tournamentTypes != null) {
        selectedTournament = $tournamentTypes.find(it => it.id === tournamentId);
    }
    $: if($gameTypes != null && selectedTournament != null) {
        selectedGame = $gameTypes.find(it => it.internalId === selectedTournament.game)
    }

    function isTournamentAvailable(t) {
        const now = new Date().getTime();
        return (t.signupOpenDate == null || t.signupOpenDate <= now) &&
        (t.signupCloseDate == null || t.signupCloseDate >= now)
    }

    let availableTournamentTypes = null;
    $: if($tournamentTypes != null) {
        availableTournamentTypes = $tournamentTypes.filter(t => isTournamentAvailable(t));
    }

    let noTourneysModal = null;
    $: if(adding && availableTournamentTypes != null && noTourneysModal != null) {
        if (availableTournamentTypes.length === 0) noTourneysModal.show();
    }

    let locked = false;
    $: if(selectedTournament != null) {
        locked = !adding && !isTournamentAvailable(selectedTournament);
    }
</script>

<style>
    .horizontal-line {
        height: 1px;
        width: 100%;
        background-color: #777777;
    }
</style>

<div class="ui container">
    <h1 class="ui inverted header">{#if adding}Create{:else}Edit{/if} team</h1>
    {#if !adding && !loading}
        {#if onWaitlist}
            <div class="ui red message">
                <p>Congratulations on creating your team! Unfortunately our tournament is currently at capacity so <b>your
                    team has been placed on the waitlist.</b> We expect a portion of teams to no-show however and our waitlist
                    is currently pretty small so <b>there is a good chance you will still get a spot in the tourney</b>.</p>
                <p>
                    Join our <a href="/discord">Discord server</a> to stay up to date with the latest tournament news.
                    We will email you as soon as we are able to get your team into the tournament.
                </p>
            </div>
        {:else}
            <div class="ui inverted message">
                Congratulations on creating your team! This team has been automatically entered into the tournament.
                Now send the invite link to your friends to add them to your team.
                We'll notify you about the dates of the first match once registrations close.
            </div>
        {/if}
    {/if}
    <div class="ui inverted form" class:loading={loading}>
        <div class="field" class:disabled={locked}>
            <label>Team Name</label>
            <input type="text" placeholder="My Epic Team" bind:value={teamName} on:input={() => dirty = true}>
        </div>
        <div class="field">
            <label>Tournament</label>
            <div class="ui selection inverted dropdown"
                 class:disabled={!adding}
                 bind:this={gameDropdown}
                 class:loading={$tournamentTypes == null || $gameTypes == null}>
                <input type="hidden"
                       on:change={e => (tournamentId = parseInt(e.target.value))}
                       value={tournamentId}>
                <i class="dropdown icon"></i>
                <div class="default text">Select a tournament</div>
                <div class="menu">
                    {#if availableTournamentTypes != null && $gameTypes != null}
                        {#each (adding ? availableTournamentTypes : $tournamentTypes) as tournament}
                            <div class="item" data-value={tournament.id}>
                                {tournament.name} - {$gameTypes.find(it => tournament.game === it.internalId).displayName}
                            </div>
                        {/each}
                    {/if}
                </div>
            </div>
        </div>
        {#if !adding && initialLoadDone && selectedGame != null && selectedTournament != null}
            <div class="field" transition:slide|local>
                <label>Team members (limit: {selectedGame.playerLimit})</label>
                <div class="ui segments">
                {#if members.length > 0}
                    <div class="ui inverted segment" transition:slide|local>
                        <MemberList
                                showDetails={!locked}
                                isSubstitute={false}
                                memberList={members}
                                onDelete={deleteMember}
                                onSwapRole={onSwapRole}
                                onMakeOwner={transferOwnership} />
                    </div>
                {/if}
                {#if !locked && members.length < selectedGame.playerLimit}
                    <div class="field ui inverted segment" transition:slide|local>
                        <label>Member invite link</label>
                        <div class="ui action input" data-tooltip="Send this link to your friends to invite them to your team!">
                            <input type="text" value={inviteUrl} bind:this={inviteUrlInput} readonly>
                            <button class="ui teal right labeled icon button" on:click={() => copyText(inviteUrlInput)}>
                                <i class="copy icon"></i>
                                Copy
                            </button>
                        </div>
                    </div>
                {/if}
                {#if members.length > selectedGame.playerLimit}
                    <div class="ui bottom attached error message" style="display: block" transition:slide|local>
                        <i class="icon fas fa-exclamation"></i>
                        You cannot save your changes as you have exceeded the allowed number of team members!
                    </div>
                {/if}
                </div>
            </div>
            {#if (selectedGame.subLimit > 0) && (!locked || subs.length > 0)}
                <div class="field" transition:slide|local>
                    <label>Substitute members (limit: {selectedGame.subLimit})</label>
                    <div class="ui segments">
                        {#if subs.length > 0}
                            <div class="ui inverted segment" transition:slide|local>
                                <MemberList
                                        showDetails={!locked}
                                        isSubstitute={true}
                                        memberList={subs}
                                        onDelete={deleteMember}
                                        onSwapRole={onSwapRole}
                                        onMakeOwner={transferOwnership} />
                            </div>
                        {/if}
                        {#if !locked && subs.length < selectedGame.subLimit}
                            <div class="field ui inverted segment" transition:slide|local>
                                <label>Substitute invite link</label>
                                <div class="ui action input" data-tooltip="Send this link to your friends to invite them to your team as a substitute!">
                                    <input type="text" value={subInviteUrl} bind:this={subInviteUrlInput} readonly>
                                    <button class="ui teal right labeled icon button" on:click={() => copyText(subInviteUrlInput)}>
                                        <i class="copy icon"></i>
                                        Copy
                                    </button>
                                </div>
                            </div>
                        {/if}
                        {#if subs.length > selectedGame.subLimit}
                            <div class="ui bottom attached error message" style="display: block" transition:slide|local>
                                <i class="icon fas fa-exclamation"></i>
                                You cannot save your changes as you have exceeded the allowed number of substitutes!
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}
            <div class="field" transition:slide|local>
                <button class="ui labeled green icon button" on:click={showTeamScheduler}>
                    <i class="calendar day icon"></i>
                    Update scheduling information
                </button>
            </div>
        {/if}
        <div class="horizontal-line" style="margin-bottom: 1em;"></div>
        <button class="ui primary button"
                on:click={saveChanges}
                class:disabled={(!adding && !dirty) || (adding && tournamentId == null) || (adding && teamName === "")}>Save</button>
        <Link to="../">
            <button class="ui button">{(adding || dirty) ? 'Cancel' : 'Close'}</button>
        </Link>
    </div>
</div>

<TeamSchedulerModal {teamName} bind:this={teamSchedulerModal} teamUlid={ulid} />

<Modal size="mini" modalOptions={{
    onHide: () => navigate('/panel/teams'),
    detachable: false
}} inverted={true} dimmerInverted={true} bind:this={noTourneysModal}>
    <div class="header">Signups closed</div>
    <div class="content">
        <p>Sorry, there are no tournaments open for signup currently. Check back often to catch the next tournament!</p>
    </div>
    <div class="actions">
        <div class="ui cancel button">Ok</div>
    </div>
</Modal>
<script>
    import SinglePage from "./SinglePage.svelte";
    import {fly} from 'svelte/transition';
    import {bestImage, webpSupported} from "../common";

    function transitionOptions(idx) {
        return {
            x: 200,
            duration: 250,
            delay: idx * 100
        }
    }
</script>

<style>
    .table-wrapper {
        height: 100%;
        background: white;
        font-size: 1.5em;
    }
    .table-wrapper .container {
        padding: 3em 0;
    }
    .table-wrapper .table {
        border-left: 0;
        border-right: 0;
    }
</style>

<SinglePage
        title={"TOURNAMENT SPECIFICATIONS"}
        backgroundImage={bestImage($webpSupported, "/images/lp-spec-header-bg.min.jpg")}>
    <div class="table-wrapper">
        <div class="ui container">
            <table class="ui structured basic large table">
                <tbody>
                <tr in:fly={transitionOptions(0)}>
                    <td class="collapsing">
                        <i class="dollar icon"></i>
                    </td>
                    <td colspan="2">Entry fee</td>
                    <td>$0 (FREE)</td>
                </tr>
                <tr in:fly={transitionOptions(1)}>
                    <td rowspan="2">
                        <i class="trophy icon"></i>
                    </td>
                    <td rowspan="2">Prize pool</td>
                    <td>1st place</td>
                    <td>$100 CAD</td>
                </tr>
                <tr in:fly={transitionOptions(2)}>
                    <td>2nd place</td>
                    <td>Assorted Steam keys</td>
                </tr>
                <tr in:fly={transitionOptions(3)}>
                    <td>
                        <i class="sitemap icon"></i>
                    </td>
                    <td colspan="2">Bracket format</td>
                    <td>Major-like (swiss)</td>
                </tr>
                <tr in:fly={transitionOptions(4)}>
                    <td>
                        <i class="broadcast tower icon"></i>
                    </td>
                    <td colspan="2">All matches streamed</td>
                    <td>
                        <i class="green check icon"></i>
                    </td>
                </tr>
                <tr in:fly={transitionOptions(5)}>
                    <td>
                        <i class="microphone alternate icon"></i>
                    </td>
                    <td colspan="2">Finals are casted</td>
                    <td>
                        <i class="green check icon"></i>
                    </td>
                </tr>
                <tr in:fly={transitionOptions(6)}>
                    <td>
                        <i class="calendar day icon"></i>
                    </td>
                    <td colspan="2">Matches are scheduled based on your availability</td>
                    <td>
                        <i class="green check icon"></i>
                    </td>
                </tr>
                <tr in:fly={transitionOptions(7)}>
                    <td class="error">
                        <i class="clock icon"></i>
                    </td>
                    <td colspan="2" class="error">Registrations close</td>
                    <td class="error">
                        November 30<sup>th</sup>, 2019
                    </td>
                </tr>
                <tr in:fly={transitionOptions(8)}>
                    <td>
                        <i class="calendar check icon"></i>
                    </td>
                    <td colspan="2">Matches begin</td>
                    <td>
                        Early-mid December
                    </td>
                </tr>
                <tr in:fly={transitionOptions(9)}>
                    <td>
                        <i class="signal icon"></i>
                    </td>
                    <td colspan="2">All matches are played online</td>
                    <td>
                        <i class="green check icon"></i>
                    </td>
                </tr>
                <tr in:fly={transitionOptions(10)}>
                    <td>
                        <i class="map icon"></i>
                    </td>
                    <td colspan="2">Map selection method</td>
                    <td>
                        Map bans
                    </td>
                </tr>
                <tr in:fly={transitionOptions(11)}>
                    <td>
                        <i class="tachometer alternate icon"></i>
                    </td>
                    <td colspan="2">Game server tickrate</td>
                    <td>
                        128
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</SinglePage>
<script>
    import Tether from "../../components/tether/Tether.svelte";
    import Schedule from "../../components/Schedule.svelte";
    import Modal from "../../components/fomantic/Modal.svelte";
    import {fade} from 'svelte/transition';
    import {apiGet, apiPost} from "../../api";
    import {toast} from "../../common";
    import {format, subMilliseconds} from 'date-fns';
    import {tick} from 'svelte';

    export let teamName = "";
    export let teamUlid;

    let modal = null;
    export function show() {
        // Reset state
        schedulingTerms = null;
        currentSchedulingTermIndex = null;
        currentSchedulingTerm = null;
        prevSchedulingTerm = null;

        document.body.style.overflowY = "hidden";

        if(modal != null) modal.show();
    }
    export function hide() {
        if(modal != null) modal.hide();
    }

    let schedulingTerms = null;
    let currentSchedulingTermIndex = null;
    async function fetchCurrentSchedule() {
        let response = await apiGet(`/teams/${teamUlid}/schedule`);
        if(!response.success) {
            toast({
                class: 'error',
                title: 'Failed to load scheduling data!',
                message: response.error,
                displayTime: 10000
            });
            return false;
        }
        schedulingTerms = response.data.terms;
        let minAvailSchedulingTerm = currentSchedulingTermIndex;
        for(const term of schedulingTerms) {
            if(term.availableForScheduling) {
                if(term.index === currentSchedulingTermIndex) break;
                else if(minAvailSchedulingTerm == null || term.index < minAvailSchedulingTerm) {
                    minAvailSchedulingTerm = term.index;
                }
            }
            // Convert entries
            if(term.entries != null) {
                term.entries = term.entries.map(entry => ({
                    startDate: new Date(entry.startDate),
                    endDate: new Date(entry.endDate)
                }));
            }
        }
        currentSchedulingTermIndex = minAvailSchedulingTerm;
        await tick();
        updateSchedule();
        return true;
    }

    let saving = false;
    async function saveAllSchedules(force) {
        saving = true;
        hideWarning();
        updateSchedule();
        if(!force) {
            for (const term of schedulingTerms) {
                if (term.availableForScheduling && term.entries.length === 0) {
                    warnWeek(term.index);
                    saving = false;
                    return;
                }
            }
        }
        for(const term of schedulingTerms) {
            if (term.availableForScheduling) {
                if(!await saveSchedule(term)) {
                    toast({
                        class: 'error',
                        title: 'Failed to save your team schedule!',
                        displayTime: 10000
                    });
                    break;
                }
            }
        }
        toast({
            class: 'success',
            message: 'Team schedule saved successfully!'
        });
        saving = false;
        hide();
    }

    async function saveSchedule(termData) {
        if(termData == null || termData.entries == null) return false;

        let response = await apiPost(`/teams/${teamUlid}/schedule`, {
            term: termData.index,
            dates: termData.entries.map(entry => ({
                s: entry.startDate.getTime(),
                e: entry.endDate.getTime()
            }))
        });
        if(!response.success) {
            console.log("Could not save schedule!", response.error);
            return false;
        }

        return true;
    }

    let currentSchedulingTerm = null;
    $: if(currentSchedulingTermIndex != null && schedulingTerms != null) {
        for(const term of schedulingTerms) {
            if(term.index === currentSchedulingTermIndex) {
                currentSchedulingTerm = term;
                break;
            }
        }
    }

    let prevSchedulingTerm = null;
    function updateSchedule() {
        if(currentSchedulingTerm != null && schedule != null) {
            if(prevSchedulingTerm != null) {
                prevSchedulingTerm.entries = schedule.getEvents();
            }
            schedule.setEvents(currentSchedulingTerm.entries);
            prevSchedulingTerm = currentSchedulingTerm;
        }
    }

    let schedule;
    async function initCalendar() {
        if(!await fetchCurrentSchedule()) hide();
    }

    function invertSchedule() {
        if(schedule != null) schedule.invert();
    }

    function clearSchedule() {
        if(schedule != null) schedule.clear();
    }

    let termsParentElement = null;
    let warningWeekElement = null;
    let warningWeekIndex = null;
    function hideWarning() {
        warningWeekElement = null;
        warningWeekIndex = null;
    }
    function warnWeek(termIndex) {
        hideWarning();

        if(termsParentElement != null && schedulingTerms != null) {
            let elementIndex = schedulingTerms
                    .filter(t => t.availableForScheduling)
                    .findIndex(t => t.index === termIndex);
            warningWeekElement = termsParentElement.children[elementIndex];
            warningWeekIndex = termIndex;
        }
    }
</script>

<style>
    .modal-content {
        padding: 0 !important;
        position: relative;
    }
    .calendar-element {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
    }
    .clickable-step {
        cursor: pointer;
    }
    .hide-on-mobile {
        display: none;
    }
    @media (hover: hover) {
        .schedule-mobile-error {
            display: none !important;
        }
        .calendar-element {
            overflow-y: initial;
        }
        .hide-on-mobile {
            display: initial;
        }
    }
    .schedule-guide {
        text-align: center;
        font-weight: bold;
        padding-bottom: 0.83em;
        font-size: 1.2em;
    }

    .tutorial-floating {
        text-align: center;
        animation: float 2s ease-in-out infinite;

        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
    }
    @keyframes float {
        0% {
            transform: translateY(-1px);
        }
        50% {
            transform: translateY(-6px);
        }
        100% {
            transform: translateY(-1px);
        }
    }
    .tutorial-floating-text {
        padding-bottom: 0.5em;
    }
    .calendar-element .steps {
        margin-top: 0;
    }
</style>
<Modal classes="overlay fullscreen" modalOptions={{
        detachable: true,
        onVisible: initCalendar,
        onHide: () => {
            document.body.style.overflowY = null;
            hideWarning();
            return true;
        }
    }} inverted={true} dimmerInverted={true} bind:this={modal}>
    <div class="header">Scheduling information for {teamName}</div>
    <div class="scrolling content modal-content">
        <div class="calendar-element">
            {#if schedulingTerms != null}
                <div class="ui active dimmer schedule-mobile-error">
                    <div class="content">
                        <div class="ui inverted icon header">
                            <i class="desktop icon"></i>
                            Switch to PC
                            <div class="sub header">Sorry, but you cannot update your team schedule while on your phone. Please switch to your computer to update your team schedule.</div>
                        </div>
                    </div>
                </div>
                <div class="ui fluid tiny inverted steps" bind:this={termsParentElement}>
                    {#each schedulingTerms as term}
                        {#if term.availableForScheduling}
                            <div class="step clickable-step"
                                 class:active={term.index === currentSchedulingTermIndex}
                                 class:disabled={!term.availableForScheduling}
                                 on:click={() => {
                                     if(term.availableForScheduling) currentSchedulingTermIndex = term.index
                                     tick().then(updateSchedule);
                                     hideWarning();
                                 }}>
                                <div class="content">
                                    <div class="title">Week {term.index + 1}</div>
                                    <div class="description">{format(new Date(term.startDate), 'MMM d')} - {format(subMilliseconds(new Date(term.endDate), 1), 'MMM d')}</div>
                                </div>
                            </div>
                        {/if}
                    {/each}
                </div>
                {#if currentSchedulingTerm != null}
                    <div class="schedule-guide">Click and drag to mark the times where your team is unavailable to play and click save.</div>
                        <!-- TODO Load this from server -->
                    <Schedule bind:this={schedule}
                              earliestHours={currentSchedulingTerm.scheduleRanges.map(it => it.startHour)}
                              latestHours={currentSchedulingTerm.scheduleRanges.map(it => it.endHour)}
                              currentlyVisibleDate={new Date(currentSchedulingTerm.startDate)}
                              onAction={hideWarning}/>
                {/if}
            {:else}
                <div class="ui active dimmer" out:fade|local={{duration:500}}>
                    <div class="ui text loader">Initializing...</div>
                </div>
            {/if}
            {#if saving}
                <div class="ui active dimmer" out:fade|local={{duration:500}}>
                    <div class="ui text loader">Saving...</div>
                </div>
            {/if}
        </div>
    </div>
    <div class="actions">
        <div class="ui labeled icon red button hide-on-mobile" on:click={clearSchedule}>
            <i class="trash icon"></i>
            Clear schedule
        </div>
        <div class="ui labeled icon orange button hide-on-mobile" on:click={invertSchedule}>
            <i class="exchange alternate icon"></i>
            Invert schedule
        </div>
        <div class="ui primary button hide-on-mobile" on:click={() => saveAllSchedules(false)}>Save</div>
        <div class="ui deny button" on:click={hide}>Cancel</div>
    </div>
</Modal>

{#if warningWeekElement != null}
    <Tether target={warningWeekElement} attachment="top center" targetAttachment="bottom center" zIndex="1000">
        <div class="ui pointing large label tutorial-floating red">
            <div class="tutorial-floating-text">You haven't filled in your availability for week {warningWeekIndex + 1} yet, are you sure you still want to save?</div>
            <button class="ui compact mini inverted button"
                    on:click={() => saveAllSchedules(true)}>Save anyways</button>
        </div>
    </Tether>
{/if}

<script>
    import {link} from 'svelte-routing';
    import {fly, fade} from 'svelte/transition';

    export let mobile = false;

    const BASE_ANIM_DELAY = 500;
</script>

<style>
    .cta-left {
        text-align: left;
        font-size: 15pt;
    }
    .mobile.cta-left {
        text-align: center;
    }
    .cta-title {
        display: inline-block;
        font-weight: 900;
        padding-bottom: 1rem;
        border-bottom: 2px solid white;
    }
    .mobile .cta-title {
        display: block;
    }
    .cta-title > h1 {
        font-size: 40pt;
    }
    .cta-title > h2 {
        font-size: 25pt;
    }

    .mobile .cta-title > h1 {
        font-size: 30pt;
    }
    .mobile .cta-title > h2 {
        font-size: 20pt;
    }
    .cta-pulse {
        margin-top: 5rem;
    }
    .mobile .cta-pulse {
        margin-top: 3rem;
    }
    .cta-pulse > .pulse-icon {
        display: inline-block;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        background: rgba(28, 177, 1, 1);
        animation: pulse-green 2s infinite;
    }
    @keyframes pulse-green {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(28, 177, 1, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(28, 177, 1, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(28, 177, 1, 0);
        }
    }
    .cta-pulse > .pulse-content {
        padding-bottom: 0.4em;
        display: inline-block;
        vertical-align: text-top;
    }
    .cta-features .grid .column {
        padding: 3rem 0;
        text-align: center;
        font-weight: 700;
        height: 13rem;
    }
    .cta-features .grid .column > div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
    .cta-features .grid .column > div > i {
        margin-bottom: 1rem;
    }
    .cta-action {
        margin-bottom: 1rem;
    }
    .cta-action > .button {
        display: inline-block;
        /*width: 45%;*/
    }
    .cta-details {
        margin-bottom: 1rem;
        font-size: 10pt;
    }
</style>
<div class="cta-left" class:mobile={mobile}>
    <div class="cta-title">
        <h1>OEF LAUNCH<br/>TOURNAMENT</h1>
        <h2>CS:GO WINGMAN (2v2)</h2>
    </div>
    <div class="cta-features">
        <div class="ui grid">
            <div class="eight wide column">
                <div>
                    <i class="big icons">
                        <i class="white large dont icon"></i>
                        <i class="white dollar sign icon"></i>
                    </i>
                    <div>NO ENTRY FEE</div>
                </div>
            </div>
            <div class="eight wide column">
                <div>
                    <i class="big trophy icon"></i>
                    <div>$100 CASH PRIZE</div>
                </div>
            </div>
        </div>
    </div>
    <div class="cta-pulse">
        <div class="pulse-icon"></div> <div class="pulse-content">REGISTRATION OPEN</div>
    </div>
    <div class="cta-details">
        Open to all high school students in Ontario
    </div>
    <div class="cta-action">
        <a class="ui primary big animated button" href="/auth/login" use:link>
            <div class="visible content">JOIN TOURNAMENT</div>
            <div class="hidden content">
                <i class="right arrow icon"></i>
            </div>
        </a>
        <!--                    <a class="ui inverted button">-->
        <!--                        MORE DETAILS-->
        <!--                    </a>-->
    </div>
</div>

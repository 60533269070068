<script>
    import { Router, Route, links, link } from "svelte-routing";
    import { tick } from "svelte";
    import Home from "./Home.svelte";
    import DropdownNav from "../components/DropdownNav.svelte";
    import Legal from "../legal/Legal.svelte";
    import Redirect from "../util/Redirect.svelte";
    import Auth from "../login/Auth.svelte";
    import NotFound from "../errorpage/NotFound.svelte";
    import SpecPage from "./SpecPage.svelte";
    import PartnersPage from "./PartnersPage.svelte";

    export let nestedPath;
    let section = "";
    $: splitSection = nestedPath.split("/");
    $: {
        if(splitSection.length > 0) {
            section = splitSection[0];
        } else {
            section = "";
        }
    }

    let dropdownOpen = false;
    // Close dropdown on page change
    $: {
        // Execute this block every time nestedPath changes
        nestedPath;
        dropdownOpen = false; // Close dropdown
        tick().then(() => window.scrollTo(0,0)); // Scroll to top
    }
</script>

<style>
    .page-content {
        width: 100%;
        height: 100%;
        padding: 4rem 0 0;
    }
    .home-menu .logo-img {
        width: 7rem !important;
    }
    .home-menu {
        font-family: "Bebas", sans-serif;
        height: 4rem;
    }
    .home-menu .item {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.2rem;
    }
    .home-menu .item.hamburger-btn {
        padding: 0;
        display: none;
    }
    .dropdown-nav-shim {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.7;
        z-index: 100;
    }
    @media only screen and (max-width: 500px) {
        .home-menu .item:not(.logo):not(.hamburger-btn) {
            display: none;
        }
        .home-menu .menu {
            display: none !important;
        }
        .home-menu .item.logo {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .home-menu .item.hamburger-btn {
            display: initial;
        }
    }
</style>

<div class="home-menu ui large top fixed inverted menu">
    <div class="ui container">
        <a class="item logo" href="/" use:link>
            <img class="logo-img" src="/images/LOGO-C-TRANS.min.png">
        </a>
        <a class="item" class:active={section === ""} use:link href="/">Home</a>
        <a class="item disabled">News</a>
        <a class="item right hamburger-btn" on:click={() => dropdownOpen = !dropdownOpen}>
            <button class="hamburger hamburger--squeeze" class:is-active={dropdownOpen} type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
        </a>
        <div class="right menu" use:links>
            <a class="item" class:active={section === "auth"} href="/auth/login">Sign-in</a>
        </div>
    </div>
</div>

{#if dropdownOpen}
    <div class="display:contents">
        <DropdownNav topMargin="4rem" items={[
            { name: "Home", link: "/", active: section === "" },
            { name: "Sign-in", link: "/auth/login", active: section === "auth" }
        ]} />
    </div>
    <div class="dropdown-nav-shim" on:click={() => dropdownOpen = false}></div>
{/if}

<div class="page-content">
    <Router>
        <Route path="/" component={Home} />
        <Route path="/legal"><Redirect target="/legal/privacy"/></Route>
        <Route path="/legal/*nestedPath" component="{Legal}" />
        <Route path="/auth/*" component="{Auth}" />
        <Route path="/tourney-specs" component="{SpecPage}" />
        <Route path="/partners" component="{PartnersPage}" />
        <Route path="/verify"><Redirect target="/panel/profile#link-steam"/></Route>
        <Route path="/*" component="{NotFound}" />
    </Router>
</div>

<script>
    import ReCaptcha from "../util/ReCaptcha.svelte";
    import SinglePage from "./SinglePage.svelte";
    import {bestImage, webpSupported} from "../common";

    function onRecaptchaSuccess(token) {
        console.log("YAY: " + token);
    }

    let recaptcha = null;
    let recaptchaReady = false;
    let loading = false;
    $: loading = recaptcha == null || !recaptchaReady;
    function executeRecaptcha() {
        if(recaptcha != null) recaptcha.execute();
    }
</script>

<style>
    .form {
        margin-top: 3rem;
    }
</style>

<SinglePage
        title={"PARTNER WITH US"}
        backgroundImage={bestImage($webpSupported, "/images/lp-partners-header-bg.min.jpg")}>
    <div class="ui form inverted container" class:loading={loading}>
        <div class="field">
            <label>Name</label>
            <input type="text" placeholder="Name">
        </div>
        <div class="field">
            <label>Email</label>
            <input type="text" placeholder="Email">
        </div>
        <div class="field">
            <label>Subject</label>
            <input type="text" placeholder="Subject">
        </div>
        <div class="field">
            <label>Message</label>
            <textarea placeholder="Enter your message" cols="30" rows="7"></textarea>
        </div>
        <button class="ui primary button" type="submit" on:click={executeRecaptcha}>Send message</button>
    </div>
</SinglePage>
<ReCaptcha onSuccess={onRecaptchaSuccess} bind:this={recaptcha} bind:ready={recaptchaReady}/>

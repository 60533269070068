<script>
    import { startOfWeek, addDays, format, getDate, addMinutes, addHours, subMinutes, isEqual, isAfter, isBefore } from 'date-fns';
    import min from 'lodash/min';
    import max from 'lodash/max';
    import randomColor from 'randomcolor';

    export let currentlyVisibleDate = new Date(); // MUST BE JS DAY OBJECT
    let weekStartDate = null;
    $: if(currentlyVisibleDate != null) {
        weekStartDate = startOfWeek(currentlyVisibleDate);
    }

    export let onAction = null;

    // Sunday, Monday, ..., Friday, Saturday
    export let earliestHours = [0, 0, 0, 0, 0, 0, 0];
    export let latestHours = [24, 24, 24, 24, 24, 24, 24];
    let minEarliestHour = 0;
    let maxLatestHour = 24;
    $: minEarliestHour = min(earliestHours);
    $: maxLatestHour = max(latestHours);

    let daysToShow = [];
    let hoursToShow = [];
    let toShowRevision = 0;
    $: if(weekStartDate != null) {
        daysToShow = [];
        let curDay = weekStartDate;
        for(let i = 0; i < 7; i++) {
            daysToShow.push(curDay);
            curDay = addDays(curDay, 1);
        }

        hoursToShow = [];
        for(let i = minEarliestHour; i < maxLatestHour; i++) {
            hoursToShow.push(addHours(weekStartDate, i));
        }

        toShowRevision++;
    }

    function dateFromDayHalfHour(dayIndex, halfHourIndex) {
        return addMinutes(daysToShow[dayIndex], (minEarliestHour * 2 + halfHourIndex) * 30);
    }

    function isDayHourDisabled(earliestHours, latestHours, dayIndex, hourIndex) {
        let earliestForDay = earliestHours[dayIndex];
        let latestForDay = latestHours[dayIndex];
        let totalHourIndex = minEarliestHour + hourIndex;
        return totalHourIndex < earliestForDay || totalHourIndex >= latestForDay;
    }

    // { name, startDate (in), endDate (ex), dragStartDate (in), isVirtual, color }
    // let curEvents = [];
    let curEvents = {};
    let virtualEvent = null;
    $: {
        virtualEvent = null;
        // for (const event of curEvents) {
        for (const [_, event] of Object.entries(curEvents)) {
            if (event != null && event.isVirtual) {
                virtualEvent = event;
                break;
            }
        }
    }

    function eventAtDate(events, date) {
        if(events.length <= 0) return;

        return events[date.getTime()];

        /*for(const event of events) {
            if(isEqual(date, event.startDate)
                    || (isAfter(date, event.startDate) && isBefore(date, event.endDate))) {
                return event;
            }
        }

        return null;*/
    }

    function formatCell(node, data) {
        const { dayIndex, hourIndex } = data;

        let cellDate = dateFromDayHalfHour(dayIndex, hourIndex);
        let wasPreviouslyNull = false;
        let currentToShowRevision = toShowRevision;

        return {
            update(data) {
                const { curEvents: eventArray, newDayIndex, newHourIndex } = data;
                if(dayIndex !== newDayIndex
                        || hourIndex !== newHourIndex
                        || currentToShowRevision !== toShowRevision) {
                    cellDate = dateFromDayHalfHour(dayIndex, hourIndex);
                    currentToShowRevision = toShowRevision;
                }
                const event = eventAtDate(eventArray, cellDate);

                if(event != null) {
                    wasPreviouslyNull = false;
                    if(event.changeQueued) {
                        node.style.backgroundColor = event.color;
                        node.textContent = "UNAVAILABLE";
                        if (event.isVirtual/* || !isEqual(event.endDate, addMinutes(cellDate, 30))*/) {
                            node.style.borderBottom = "2px solid white";
                            node.style.borderLeft = "2px solid white";
                            node.style.borderTop = "2px solid white";
                        } else {
                            node.style.borderBottom = "1px solid grey";
                            node.style.borderLeft = "initial";
                            node.style.borderTop = "initial";
                        }
                        if (event.isVirtual) {
                            node.style.transform = "scale(1.2)";
                            node.style.borderRight = "2px solid white";
                        } else {
                            node.style.transform = null;
                            node.style.borderRight = "1px solid grey";
                        }
                    }
                } else if(!wasPreviouslyNull) {
                    wasPreviouslyNull = true;
                    node.textContent = "";
                    node.style.backgroundColor = null;
                    node.style.borderBottom = "1px solid grey";
                    node.style.borderRight = "1px solid grey";
                    node.style.borderLeft = "initial";
                    node.style.borderTop = "initial";
                    node.style.transform = null;
                }
            }
        };
    }

    // Event-based scheduleing system.
    /*function addVirtualEventStartingAt(startDate) {
        let event;
        if(virtualEvent != null) {
            event = virtualEvent;
        } else {
            event = {};
            curEvents.push(event);
        }

        event.name = "";
        event.startDate = startDate;
        event.dragStartDate = startDate;
        event.endDate = addMinutes(startDate, 30);
        // event.color = randomColor({
        //     alpha: 1
        // });
        event.color = "red";
        event.isVirtual = true;

        // Force reload events array
        curEvents = curEvents;
    }
    function expandEventTo(event, newDate) {
        if(isEqual(newDate, event.dragStartDate) || isAfter(newDate, event.dragStartDate)) {
            let resultDate = addMinutes(newDate, 30);
            for (const possibleConflict of curEvents) {
                if (possibleConflict !== event) {
                    if (isAfter(resultDate, possibleConflict.startDate)
                            && isBefore(event.startDate, possibleConflict.startDate)) {
                        return false;
                    }
                }
            }
            event.startDate = event.dragStartDate;
            event.endDate = resultDate;
        } else {
            for (const possibleConflict of curEvents) {
                if (possibleConflict !== event) {
                    if (isBefore(newDate, subMinutes(possibleConflict.endDate, 30))
                            && (isAfter(event.dragStartDate, possibleConflict.startDate)
                                    || isEqual(event.dragStartDate, possibleConflict.startDate))) {
                        return false;
                    }
                }
            }
            event.startDate = newDate;
            event.endDate = addMinutes(event.dragStartDate, 30);
        }
        return true;
    }

    function expandVirtualEventTo(newDate) {
        if(virtualEvent != null) {
            expandEventTo(virtualEvent, newDate);

            // Force reload events array
            curEvents = curEvents;
        }
    }
    function finalizeVirtualEventAt(newDate) {
        if(virtualEvent != null) {
            expandEventTo(virtualEvent, newDate);
            virtualEvent.isVirtual = false;

            // Force reload events array
            curEvents = curEvents;
        }
    }*/
    // Color based event system
    let mouseDown = false;
    let adding = false;
    function toggleEventAt(eventDate) {
        if(mouseDown) {
            let existingEvent = eventAtDate(curEvents, eventDate);
            if(virtualEvent != null && virtualEvent !== existingEvent) virtualEvent.isVirtual = false;

            if(existingEvent == null && adding) {
                let newEvent = {
                    // name: "",
                    startDate: eventDate,
                    // dragStartDate: eventDate,
                    // endDate: addMinutes(eventDate, 30),
                    color: "red",
                    isVirtual: true,
                    changeQueued: true
                };
                // curEvents.push(newEvent);
                curEvents[eventDate.getTime()] = newEvent;

                if(onAction != null) onAction();

                return newEvent;
            } else if(existingEvent != null && !adding) {
                /*const origIndex = curEvents.indexOf(existingEvent);
                if(origIndex != null) {
                    curEvents.splice(origIndex, 1);

                    // Force-reload events
                    curEvents = curEvents;
                }*/
                delete curEvents[eventDate.getTime()];
                // Force trigger update. https://github.com/sveltejs/svelte/issues/3211
                curEvents = curEvents;

                if(onAction != null) onAction();
            }

            return existingEvent;
        }
        return null;
    }
    function addVirtualEventStartingAt(startDate, disabled) {
        if(!disabled) {
            mouseDown = true;
            adding = eventAtDate(curEvents, startDate) == null;
            toggleEventAt(startDate);
        }
    }
    function expandVirtualEventTo(newDate, disabled) {
        if(!disabled) toggleEventAt(newDate);
    }
    function finalizeVirtualEventAt(newDate, disabled) {
        if(disabled) finalizeVirtualEvent();
        else {
            let resultingEvent = toggleEventAt(newDate);
            if (resultingEvent != null) {
                resultingEvent.isVirtual = false;
                resultingEvent.changeQueued = true;

                // Force-reload events
                curEvents = curEvents;
            }
            mouseDown = false;
        }
    }
    function finalizeVirtualEvent() {
        if(virtualEvent != null) {
            virtualEvent.changeQueued = true;
            virtualEvent.isVirtual = false;
        }
        mouseDown = false;
        // Force-reload events
        curEvents = curEvents;
    }

    export function getEvents() {
        return Object.values(curEvents).filter(v => v != null).map(({startDate}) => {
            return {
                startDate,
                endDate: addMinutes(startDate, 30)
            };
        });
    }

    export function setEvents(events) {
        clear();
        for(let d = 0; d < 7; d++) {
            const day = daysToShow[d];

            for(let i = 0; i < 48; i++) {
                if(!isDayHourDisabled(d, Math.floor(i / 2) - minEarliestHour)) {
                    const newDate = addMinutes(day, i * 30);
                    const endDate = addMinutes(newDate, 30);
                    const newDateTime = newDate.getTime();


                    for (const possibleEvent of events) {
                        if ((isAfter(newDate, possibleEvent.startDate)
                                || isEqual(newDate, possibleEvent.startDate))
                                && (isBefore(endDate, possibleEvent.endDate)
                                        || isEqual(endDate, possibleEvent.endDate))) {
                            curEvents[newDateTime] = {
                                startDate: newDate,
                                color: "red",
                                isVirtual: false,
                                changeQueued: true
                            };
                            break;
                        }
                    }
                }
            }
        }
    }

    export function clear() {
        curEvents = {};
        mouseDown = false;
        adding = false;
        if(onAction != null) onAction();
    }

    export function invert() {
        for(let d = 0; d < 7; d++) {
            const day = daysToShow[d];

            for(let i = 0; i < 48; i++) {
                const newDate = addMinutes(day, i * 30);
                const newDateTime = newDate.getTime();
                if(curEvents[newDateTime] != null) {
                    delete curEvents[newDateTime];
                    // Force trigger update. https://github.com/sveltejs/svelte/issues/3211
                    curEvents = curEvents;
                } else if(!isDayHourDisabled(d, Math.floor(i / 2) - minEarliestHour)) {
                    curEvents[newDateTime] = {
                        startDate: newDate,
                        color: "red",
                        isVirtual: false,
                        changeQueued: true
                    };
                }
            }
        }
        if(onAction != null) onAction();
    }
</script>

<style>
    .nd-schedule-table-wrapper {
        width: 100%;
        overflow-x: hidden;
    }
    .nd-schedule-table {
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        user-select: none;
        border-spacing: 0;
    }
    .nd-schedule-table td, .nd-schedule-table th {
        border-right: 1px solid grey;
        border-bottom: 1px solid grey;
    }
    .nd-schedule-table th {
        padding: 0.3em;
        border-top: 1px solid grey;
    }
    .nd-schedule-table th, .nd-schedule-table tbody {
        background-color: #191a1b;
    }
    .nd-schedule-table-header-month {
        opacity: 0.5;
    }
    .nd-schedule-table-header-day {
        font-size: 2em;
    }
    .nd-schedule-table .nd-schedule-table-header-corner {
        width: 7em;
        background-color: black;
        border-top: none;
        border-left: none;
    }
    .nd-schedule-table-header th {
        position: sticky;
        top: 0;
    }
    .nd-schedule-table-time {
        text-align: center;
        vertical-align: top;
        padding: 0.3em;
        border-left: 1px solid grey;
    }
    .nd-schedule-table-row {
        height: 2em;
    }
    .nd-schedule-table-cell {
        background: #191a1b;
        height: 1em;
        transition: 0.25s transform, 0.25s background-color;
        transform-origin: center;
        text-align: center;
        font-weight: bold;
    }
    .nd-schedule-table .nd-schedule-table-row-group:hover {
        background-color: #282828;
    }
    .nd-schedule-table-cell.disabled {
        background-color: grey;
    }
</style>

<div class="nd-schedule-table-wrapper">
    <table class="nd-schedule-table" on:mouseleave={finalizeVirtualEvent}>
        <thead class="nd-schedule-table-header">
            <tr>
                <th class="nd-schedule-table-header-corner"></th>
                {#each daysToShow as day, index}
                    <th>
                        {#if getDate(day) === 1 || index === 0}
                            <div class="nd-schedule-table-header-month">{format(day, 'MMMM')}</div>
                        {:else}
                            <div>&nbsp;</div>
                        {/if}
                        <div>{format(day, 'EEE')}</div>
                        <div class="nd-schedule-table-header-day">{getDate(day)}</div>
                    </th>
                {/each}
            </tr>
        </thead>
        {#each hoursToShow as baseHour, baseHourIndex}
            <tbody class="nd-schedule-table-row-group">
                {#each [{h: baseHour, i: baseHourIndex * 2}, {h: addMinutes(baseHour, 30), i: baseHourIndex * 2 + 1}] as hour}
                    <tr class="nd-schedule-table-row">
                        {#if hour.i % 2 === 0}
                            <td class="nd-schedule-table-time" rowspan="2">{format(hour.h, 'p')}</td>
                        {/if}
                        {#each daysToShow as day, index}
                            <td class="nd-schedule-table-cell"
                                class:disabled={isDayHourDisabled(earliestHours, latestHours, index, baseHourIndex)}
                                use:formatCell={{curEvents: curEvents, dayIndex: index, hourIndex: hour.i}}
                                on:mousedown={() => addVirtualEventStartingAt(dateFromDayHalfHour(index, hour.i), isDayHourDisabled(earliestHours, latestHours, index, baseHourIndex))}
                                on:mouseover={() => expandVirtualEventTo(dateFromDayHalfHour(index, hour.i), isDayHourDisabled(earliestHours, latestHours, index, baseHourIndex))}
                                on:mouseup={() => finalizeVirtualEventAt(dateFromDayHalfHour(index, hour.i), isDayHourDisabled(earliestHours, latestHours, index, baseHourIndex))}>
                            </td>
                        {/each}
                    </tr>
                {/each}
            </tbody>
        {/each}
    </table>
</div>
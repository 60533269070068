<script>
    export let side = "t";

    export let onClick = null;
    export let clickable = false;
</script>
<style>
    .highlight {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: 0.25s opacity;
        background-image: radial-gradient(rgba(255,255,255,0.5), transparent 75%);
        pointer-events: none;
        opacity: 0;
    }
    .img-wrapper {
        position: relative;
        display: inline-block;
        height: 12rem;
    }
    .display-inline-block-wrapper:hover .highlight {
        opacity: 1;
    }
    .display-inline-block-wrapper {
        display: inline-block;
        cursor: pointer;
        padding: 0.5em;
    }
    .disabled {
        pointer-events: none;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    img {
        width: 12rem;
    }
    .label {
        padding-top: 1em;
    }
</style>
<div class="display-inline-block-wrapper" class:disabled={!clickable} on:click={() => {if(onClick != null) onClick();}}>
    <div class="wrapper">
        <div class="img-wrapper">
            <div class="highlight"></div>
            <img src="/images/map-bans/{side}_patch.png" draggable="false"/>
        </div>
        <div class="label">{side === "t" ? "Terrorists" : "Counter-Terrorists"}</div>
    </div>
</div>

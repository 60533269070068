<script>
    import SimpleMarkdown from 'simple-markdown';
    import {link} from 'svelte-routing';
    import {apiGet} from '../api';
    import {toast} from '../common';
    import GiveawayAction from './GiveawayAction.svelte';
    import Time from '../components/util/Time.svelte';

    export let ulid = null;
    let loadingPage = true;

    let giveaway = null;

    async function loadGiveawayFromUlid(ulid) {
        loadingPage = true;

        try {
            let response = await apiGet(`/giveaway/${ulid}`);
            if (response.success) {
                giveaway = response.data;
            }
        } catch(e) {
            console.error(e);
            toast({
                class: 'error',
                title: 'Failed to load giveaway information, please refresh the page and try again!',
                displayTime: 10000
            });
        }

        loadingPage = false;
    }

    $: if(ulid != null) {
        loadGiveawayFromUlid(ulid);
    }

    const mdRules = SimpleMarkdown.defaultRules;
    const mdRawBuiltParser = SimpleMarkdown.parserFor(mdRules);
    const mdParse = function(source) {
        return mdRawBuiltParser(source, {inline: true});
    };
    const mdHtmlOutput = SimpleMarkdown.htmlFor(SimpleMarkdown.ruleOutput(mdRules, 'html'));

    function renderPrizeBlurb(prizeBlurb) {
        const ast = mdParse(prizeBlurb);
        return mdHtmlOutput(ast);
    }

    let time = 0;
</script>

<style>
    .grid.main {
        height: 100%;
    }

    .column {
        max-width: 450px;
    }

    .login-logo {
        width: 300px;
        margin-bottom: 30px;
    }

    .giveaway-button {
        font-family: Roboto,Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
        font-weight: 900;
    }
    .giveaway-desc {
        padding-bottom: 0.5em;
    }

    .prize-blurb {
        font-size: 1.5em;
    }
</style>

<Time bind:time={time}/>

{#if giveaway != null}
<div class="ui middle aligned center aligned grid main">
    <div class="column">
        <a href="/" use:link>
            <img src="/images/LOGO-C-TRANS.min.png" class="image login-logo" alt="WREL Logo">
        </a>

        <div class="ui stacked segments">
            <div class="ui inverted segment">
                <h1 class="ui">{giveaway.title}</h1>
            </div>
            <div class="ui inverted segment">
                <div class="ui three statistics inverted">
                    <div class="statistic">
                        <div class="value">
                            5
                        </div>
                        <div class="label">
                            Your entries
                        </div>
                    </div>
                    <div class="statistic">
                        <div class="value">
                            25
                        </div>
                        <div class="label">
                            Total entries
                        </div>
                    </div>
                    <div class="statistic">
                        <div class="value">
                            {Math.max(Math.floor((giveaway.endDate - time)/86400000), 0)}
                        </div>
                        <div class="label">
                            Days remaining
                        </div>
                    </div>
                </div>

            </div>
            <div class="ui inverted segment">
                <h5 class="ui giveaway-desc">{giveaway.actionDesc}</h5>

                <div class="ui relaxed divided list inverted">
                    {#each giveaway.actions as action}
                        <GiveawayAction {action} />
                    {/each}
                </div>
            </div>
        </div>

        <div class="ui inverted message prize-blurb">
            <p>{@html renderPrizeBlurb(giveaway.prizeBlurb)}</p>
        </div>
    </div>
</div>
{/if}

{#if loadingPage}
    <div class="ui page active dimmer">
        <div class="ui text loader">Loading...</div>
    </div>
{/if}


<script>
    // __________________________________________________________________________________
    // WARNING THAT RELOADING THE STREAM SERVER BROWSER CACHE CAN CAUSE VIDEO LAG
    // ALWAYS RUN THROUGH A COMPLETE GAME AFTER RELOADING THE STREAM SERVER BROWSER CACHE
    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

    import anime from 'animejs';
    import {outerHeightWithMargin, outerWidthWithMargin} from './util';
    import {onMount, tick} from 'svelte';
    import {fly} from 'svelte/transition';
    import StateMachine from 'javascript-state-machine';
    import formatDuration from 'format-duration';
    import Time from '../components/util/Time.svelte';
    import {parse as parseM3u} from 'm3ujs';
    import MapCard from "../mapbans/MapCard.svelte";
    import {STATE_MAP_NOT_READY} from "../mapbans/MapCardState";
    import {WS_URL} from "../common";

    let stateMachine;
    let stateMachineTransitioning = false;
    let stateQueue = [];

    const DEBUG = location.hash != null && location.hash.toLowerCase() === "#debug";

    const BGM_VOLUME = 0.2;

    let overlayState = {
        showHypeVideoTime: -1,

        mapBansStartTime: -1,
        allPlayersInServer: false,

        tournamentName: "",
        tournamentLayout: "",

        bo: 1,
        boGame: 1,
        team1Name: "",
        team2Name: "",
        htVideoEnabled: true,

        halftimeEndTime: null,
        team1Score: 0,
        team2Score: 0,
        otIndex: 0,

        gameOver: false,

        config: {
            halftimeVideo: "",
            gameOverVideo: ""
        },

        maps: {},
        mapStatus: {},
        gameStartTime: null,
        gameResults: [],
        hasNextGame: false,
        nextGameStartTime: 0,

        casterSteamId: ""
    };

    const CSGO_FOOTER_HEIGHT = 50;

    const CASTER_ICONS = {
        // Strings because JS longs are too tiny
        "76561198368612480": {
            url: "https://s3.wasabisys.com/oef/automation/overlay/icons/caster-nortel.webm",
            right: -25,
            bottom: 25 + CSGO_FOOTER_HEIGHT,
            scale: 0.15
        }
    };

    const SOCIAL_ICONS_LEFT = [
        {
            url: "https://s3.wasabisys.com/oef/automation/overlay/icons/social-ig.webm",
            left: -100,
            bottom: -150,
            scale: 0.4
        },
        {
            url: "https://s3.wasabisys.com/oef/automation/overlay/icons/social-website.webm",
            left: -50,
            bottom: -150,
            scale: 0.4
        },
        {
            url: "https://s3.wasabisys.com/oef/automation/overlay/icons/social-yt.webm",
            left: -225,
            bottom: -200,
            scale: 0.5
        }
    ];

    // Shift all icons up 128 pixels to accommodate for song name strip
    const SOCIAL_ICONS_COUNTDOWN_LEFT = SOCIAL_ICONS_LEFT.map(it => {
        let result = {...it};
        result.bottom += 128;
        return result;
    });

    // Shift all icons up some pixels to accommodate for CSGO footer
    const SOCIAL_ICONS_CSGO_LEFT = SOCIAL_ICONS_LEFT.map(it => {
        let result = {...it};
        result.bottom += CSGO_FOOTER_HEIGHT;
        return result;
    });

    let socialIconLeftRotation = randomIntFromInterval(0, SOCIAL_ICONS_LEFT.length - 1);

    function shuffleArray(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    let countdownSongNameWrapper = null; // Bound element
    let oldCountdownSongName = null; // Bound element
    let newCountdownSongName = null; // Bound element

    let countdownMusicId = 0;
    let curCountdownAudio = null;
    let oldCountdownAudioTitle = "Initializing...";
    let newCountdownAudioTitle = "";
    let curScrollTl = null;
    const OVERLAY_MUSIC_BASE_URL = "/overlay-music";
    const TRANSLATE_X_REGEX = /translateX\((-?[\d.]*)(px)?\)/;

    async function revealNewCountdownMusicTitle(newSongName) {
        oldCountdownAudioTitle = newCountdownAudioTitle;
        newCountdownAudioTitle = newSongName;
        if (curScrollTl != null) {
            curScrollTl.pause();
            // Copy only translateX
            let matched = newCountdownSongName.style.transform.match(TRANSLATE_X_REGEX);
            if (matched != null && matched[1] != null) {
                oldCountdownSongName.style.transform = `translateX(${matched[1]}px)`;
            } else {
                oldCountdownSongName.style.transform = "translateX(0%)";
            }
            newCountdownSongName.style.transform = "translateY(100%)";
        }
        await tick();
        const outOldTl = anime({
            targets: [oldCountdownSongName],
            translateY: ['0%', '-100%'],
            duration: 500,
            easing: 'easeInOutElastic'
        }).finished;
        const inNewTl = anime({
            targets: [newCountdownSongName],
            translateY: ['100%', '0%'],
            duration: 500,
            easing: 'easeInOutElastic'
        }).finished;
        await outOldTl;
        await inNewTl;
        let widthDiff = outerWidthWithMargin(newCountdownSongName) - outerWidthWithMargin(countdownSongNameWrapper);
        if (widthDiff > 0) {
            const scrollTl = anime.timeline({
                easing: 'linear',
                loop: true
            }).add({
                targets: [newCountdownSongName],
                translateX: [0, -widthDiff],
                duration: widthDiff * 10,
                endDelay: 2000
            }, '+=2000');
            curScrollTl = scrollTl;
        }
    }

    async function beginCountdownMusic() { // You probably shouldn't await on this
        if (curCountdownAudio != null) return;

        await tick();
        let curAudioId = ++countdownMusicId;
        let response = await fetch(`${OVERLAY_MUSIC_BASE_URL}/OEF Playlist.m3u`);
        let playlistText = await response.text();

        let parsedPlaylist = parseM3u(playlistText);

        let playlist = [...parsedPlaylist.tracks];
        shuffleArray(playlist);

        let curTrackIndex = -1;

        async function playNextInPlaylist(curAudioId) {
            if (curAudioId !== countdownMusicId) return;

            curTrackIndex++;
            if (curTrackIndex >= playlist.length) {
                shuffleArray(playlist);
                curTrackIndex = 0;
            }

            // Pause old audio if any
            if (curCountdownAudio != null) {
                try {
                    curCountdownAudio.pause();
                } catch (e) {
                }
            }

            let curPlaylistEntry = playlist[curTrackIndex];

            let audio = new Audio(`${OVERLAY_MUSIC_BASE_URL}/${encodeURIComponent(curPlaylistEntry.file)}`);
            audio.onerror = function (event) {
                console.error("Countdown audio onerror:", event);
                playNextInPlaylist(curAudioId);
            };
            audio.onended = function () {
                playNextInPlaylist(curAudioId);
            };
            audio.volume = BGM_VOLUME;
            try {
                curCountdownAudio = audio;
                await revealNewCountdownMusicTitle(curPlaylistEntry.title);
                audio.play();
            } catch (e) {
                console.error("Could not play audio:", e);
                playNextInPlaylist(curAudioId);
            }
        }

        // TODO Remove debugging
        window.audioNext = () => playNextInPlaylist(curAudioId);
        playNextInPlaylist(curAudioId);
    }

    async function stopCountdownMusic() {
        countdownMusicId++;
        if (curCountdownAudio != null) {
            let audioInstance = curCountdownAudio;
            curCountdownAudio = null;
            await anime({
                targets: [audioInstance],
                volume: [BGM_VOLUME, 0.0],
                easing: 'linear',
                duration: 1000,
            }).finished;
            audioInstance.pause();
        }
    }

    function winningTeamText(state) {
        if (state.team1Score > state.team2Score) {
            return state.team1Name + " wins!"
        } else if (state.team2Score > state.team1Score) {
            return state.team2Name + " wins!"
        } else {
            return "Match tied!"
        }
    }

    let now = 0;

    async function playVideo(element) {
        element.currentTime = 0;
        await new Promise((resolve, reject) => {
            element.onended = () => {
                resolve();
            };
            try {
                element.play();
            } catch (e) {
                reject(e);
            }
        });
    }

    let transitioning = false;
    let transitionQueue = [];

    // Transition function must be async
    async function evalAnimation(animation) {
        if (transitioning) {
            await new Promise((resolve, reject) => {
                transitionQueue.push(() => animation().then(() => resolve()));
            });
        } else {
            transitioning = true;
            // First play current animation
            await animation();
            // Play queued animations
            let nextAnimation;
            while ((nextAnimation = transitionQueue.shift()) != null) {
                await nextAnimation();
            }
            transitioning = false;
        }
    }

    /* Loading */
    let overlayLoadingElement = null;

    /* Background */
    let backgroundVideo = null;

    /* Transition */
    let overlayTransitionElement = null;

    /* Countdown */
    let countdownElement = null;
    let countdownHeaderLeft = null;
    let countdownHeaderRight = null;
    let countdownHeaderCenter = null;
    let countdownHeaderTimer = null;
    let countdownFooterLogo = null;
    let countdownFooterSongWrapper = null;

    // Spam social media icon thing on countdown
    let socialMediaInterval = null;

    /* Hype video */
    let hypeVideoElement = null;

    /* Waiting for teams */
    let waitingForTeamsTeamNames = null;
    let waitingForTeamsBo = null;
    let waitingForTeamsBottom = null;

    /* Map bans */
    let mapBansHeaderLeft = null;
    let mapBansHeaderCenter = null;
    let mapBansHeaderBottom = null;
    let mapBansHeaderRight = null;
    let mapBansCards = null;
    let mapBansCountdown = null;

    /* Halftime */
    let halftimeVideoElement = null;
    let halftimeLeftText = null;
    let halftimeLeftLogo = null;
    let halftimeRight = null;
    let halftimeTeamNames = null;
    let halftimeScore = null;
    let halftimeTimer = null;

    /* Game over */
    let gameOverVideoElement = null;
    let gameOverTop = null;
    let gameOverBo = null;
    let gameOverGameResults = null;
    let gameOverNextGameNotif = null;

    /* Segments */
    let animatedIconSegmentElement = null;
    let animatedIconSegmentVideoElement = null;
    let animatedIconSegmentVideoUrl = null;

    let hypeVideoDuration = null;
    let hypeVideoDurationCallback = null;

    function notifyHypeVideoMetaReady() {
        hypeVideoDuration = hypeVideoElement.duration;
        if (hypeVideoDurationCallback != null) {
            hypeVideoDurationCallback(hypeVideoDuration);
            hypeVideoDurationCallback = null;
        }
    }

    function onHypeVideoDurationReady(cb) {
        if (hypeVideoDuration != null) cb(hypeVideoDuration);
        else hypeVideoDurationCallback = cb;
    }

    async function playAnime(animation, reverse, instant) {
        if (animation.reversed !== reverse) {
            animation.completed = true; // Workaround https://github.com/juliangarnier/anime/issues/623
            animation.reverse();
            animation.completed = false; // Workaround https://github.com/juliangarnier/anime/issues/623
        }

        if (instant === true) {
            animation.completed = false;
            const now = performance.now();
            animation.tick(now);
            animation.tick(now + animation.duration + 1);
        } else {
            // Remove this entire if statement if reusing animation object
            if (animation.reversed) {
                animation.seek(animation.duration);
            } else {
                animation.seek(0);
            }
            animation.completed = false; // Workaround https://github.com/juliangarnier/anime/issues/623
            animation.play();
        }
        await animation.finished;
    }

    let revealMapBansCountdownAnime;

    /* Countdown upgrade */
    const upgradeCountdownScreenAnime = () => anime.timeline({
        easing: 'easeOutQuint',
        autoplay: false
    }).add({
        targets: [countdownHeaderLeft, countdownHeaderCenter, countdownHeaderRight],
        translateY: ['0%', '-100%'],
        duration: 1000
    }).add({
        targets: [countdownFooterSongWrapper, countdownFooterLogo],
        translateY: ['0%', '100%'],
        duration: 1000
    }, '-=1000').add({
        targets: countdownHeaderTimer,
        marginTop: [outerHeightWithMargin(countdownHeaderCenter) + 'px', '0px'],
        top: ['0%', '50%'],
        translateY: ['0%', '-50%'],
        minWidth: [outerWidthWithMargin(countdownHeaderCenter) + 'px', '0px'],
        color: '#fff',
        fontSize: '12em',
        duration: 2000
    }, '-=1000').add({
        targets: countdownHeaderTimer,
        backgroundColor: ['rgba(255,255,0,1.0)', 'rgba(255,255,0,0.0)'],
        duration: 1000
    }, '-=2000').add({
        targets: countdownElement,
        backgroundColor: '#000',
        duration: 2000
    }, '-=2000');

    async function upgradeCountdown(instant) {
        countdownHeaderTimer.style.position = 'fixed';
        countdownHeaderTimer.style.marginTop = outerHeightWithMargin(countdownHeaderCenter) + 'px';
        countdownHeaderTimer.style.left = '50%';
        countdownHeaderTimer.style.transform = 'translateX(-50%)';

        await playAnime(upgradeCountdownScreenAnime(), false, instant);
    }

    window.upgradeCountdown = upgradeCountdown;

    let tickAudio = new Audio("https://s3.wasabisys.com/oef/automation/tick.wav");
    tickAudio.load();

    let lastCountdownTime = 999999999;
    let countdownUpgraded = false;
    $: {
        let newCountdownTime = Math.max(overlayState.showHypeVideoTime - now, 0);
        if(Math.floor(newCountdownTime) !== Math.floor(lastCountdownTime)) {
            lastCountdownTime = newCountdownTime;

            if(newCountdownTime <= 10000) {
                if (stateMachine != null
                        && stateMachine.state === "countdown"
                        && !countdownUpgraded) {
                    if (socialMediaInterval != null) {
                        clearInterval(socialMediaInterval);
                        socialMediaInterval = null;
                    }
                    stopCountdownMusic();
                    upgradeCountdown();
                    countdownUpgraded = true;
                }

                /*try {
                    tickAudio.pause();
                } catch(e) {}
                try {
                    tickAudio.currentTime = 0;
                    tickAudio.play();
                } catch(e) {}*/
            }
        }
    }

    onMount(async () => {
        setTimeout(() => {
            /* Loading */
            const overlayLoadingAnime = () => anime({
                easing: 'linear',
                targets: [overlayLoadingElement],
                autoplay: false,
                opacity: [0.0, 1.0],
                duration: 500
            });

            async function hideLoading(instant) {
                await playAnime(overlayLoadingAnime(), true, instant);
            }

            async function showLoading(instant) {
                await playAnime(overlayLoadingAnime(), false, instant);
            }

            /* Countdown */
            const countdownScreenAnime = () => anime.timeline({
                easing: 'easeOutQuint',
                autoplay: false
            })
                    // Animate header
                    .add({
                        targets: countdownHeaderCenter,
                        translateY: ['-100%', '0%'],
                        duration: 500
                    })
                    .add(animeClipReveal(countdownHeaderLeft, 'right'))
                    .add(animeClipReveal(countdownHeaderRight, 'left'), '-=500')
                    .add({
                        targets: countdownHeaderTimer,
                        rotateX: ['-90deg', 0],
                        duration: 500
                    })

                    // Animate footer
                    .add({
                        targets: countdownFooterLogo,
                        translateY: ['100%', '0%'],
                        duration: 500
                    })
                    .add(animeClipReveal(countdownFooterSongWrapper, 'right'));

            async function hideCountdown(instant) {
                await playAnime(countdownScreenAnime(), true, instant);
            }

            async function showCountdown(instant) {
                await playAnime(countdownScreenAnime(), false, instant);
            }

            /* Loading */
            const hypeVideoAnime = () => anime({
                easing: 'linear',
                targets: [hypeVideoElement],
                autoplay: false,
                opacity: [0.0, 1.0],
                duration: 500
            });

            async function hideHypeVideo(instant) {
                await playAnime(hypeVideoAnime(), true, instant);
            }

            async function showHypeVideo(instant) {
                await playAnime(hypeVideoAnime(), false, instant);
            }

            /* Waiting for teams */
            const waitingForTeamsAnime = () => anime.timeline({
                easing: 'easeOutQuint',
                autoplay: false
            })
                    .add({
                        targets: waitingForTeamsTeamNames,
                        translateX: ['-50%', '0%'],
                        opacity: [0.0, 1.0],
                        duration: 500
                    })
                    .add(animeClipReveal(waitingForTeamsBo, 'left'))
                    .add({
                        targets: waitingForTeamsBottom,
                        rotateX: ['-90deg', 0],
                        duration: 500
                    });

            async function hideWaitingForTeams(instant) {
                await playAnime(waitingForTeamsAnime(), true, instant);
            }

            async function showWaitingForTeams(instant) {
                await playAnime(waitingForTeamsAnime(), false, instant);
            }

            /* Map bans */
            const mapBansAnime = () => anime.timeline({
                easing: 'easeOutQuint',
                autoplay: false
            })
                    // Animate header
                    .add({
                        targets: mapBansHeaderCenter,
                        translateY: ['-100%', '0%'],
                        duration: 500
                    })
                    .add(animeClipReveal(mapBansHeaderLeft, 'right'))
                    .add(animeClipReveal(mapBansHeaderRight, 'left'), '-=500')
                    .add({
                        targets: mapBansHeaderBottom,
                        rotateX: ['-90deg', 0],
                        duration: 500
                    })
                    .add({
                        targets: mapBansCards.children,
                        rotateY: ['90deg', '0deg'],
                        opacity: [0, 1],
                        duration: 500,
                        delay: anime.stagger(100)
                    })
                    .add({
                        targets: mapBansCountdown,
                        opacity: [0, 1],
                        duration: 500
                    }, '-=500');

            async function hideMapBans(instant) {
                await playAnime(mapBansAnime(), true, instant);
            }

            async function showMapBans(instant) {
                for(const child of mapBansCards.children) {
                    child.style.willChange = "transform, opacity";
                }
                await playAnime(mapBansAnime(), false, instant);
                for(const child of mapBansCards.children) {
                    child.style.willChange = null;
                }
            }

            revealMapBansCountdownAnime = () => anime({
                ...animeClipReveal(mapBansCountdown, 'left'),
                autoplay: false
            });

            /* Game */
            const gameAnime = () => anime({
                easing: 'linear',
                targets: [backgroundVideo],
                autoplay: false,
                opacity: [1.0, 0.0],
                duration: 500
            });

            async function hideGame(instant) {
                await playAnime(gameAnime(), true, instant);
            }

            async function showGame(instant) {
                await playAnime(gameAnime(), false, instant);
            }

            /* Halftime */
            const halftimeVideoAnime = () => anime({
                easing: 'linear',
                targets: [halftimeVideoElement],
                autoplay: false,
                opacity: [0.0, 1.0],
                duration: 500
            });

            async function hideHalftimeVideo(instant) {
                if(halftimeVideoElement != null)
                    await playAnime(halftimeVideoAnime(), true, instant);
            }

            async function showHalftimeVideo(instant) {
                if(halftimeVideoElement != null)
                    await playAnime(halftimeVideoAnime(), false, instant);
            }

            const halftimeAnime = () => anime.timeline({
                easing: 'easeOutQuint',
                autoplay: false
            })
                    .add(animeClipReveal(halftimeLeftText, 'top'))
                    .add(animeClipReveal(halftimeLeftLogo, 'left'))
                    .add(animeClipReveal(halftimeRight, 'right'), '-=1000')
                    .add({
                        targets: halftimeTeamNames,
                        translateY: ['-50%', '0%'],
                        opacity: [0.0, 1.0],
                        duration: 500
                    })
                    .add({
                        targets: halftimeTimer,
                        translateY: ['50%', '0%'],
                        opacity: [0.0, 1.0],
                        duration: 500
                    }, '-=500')
                    .add({
                        targets: halftimeScore,
                        opacity: [0.0, 1.0],
                        duration: 500
                    });

            async function hideHalftime(instant) {
                await playAnime(halftimeAnime(), true, instant);
            }

            async function showHalftime(instant) {
                await playAnime(halftimeAnime(), false, instant);
            }

            /* Game over */
            const gameOverVideoAnime = () => anime({
                easing: 'linear',
                targets: [gameOverVideoElement],
                autoplay: false,
                opacity: [0.0, 1.0],
                duration: 500
            });

            async function hideGameOverVideo(instant) {
                if(gameOverVideoElement != null)
                    await playAnime(gameOverVideoAnime(), true, instant);
            }

            async function showGameOverVideo(instant) {
                if(gameOverVideoElement != null)
                    await playAnime(gameOverVideoAnime(), false, instant);
            }

            const gameOverAnime = () => {
                let resultAnime = anime.timeline({
                    easing: 'easeOutQuint',
                    autoplay: false
                })
                        // Keep entire gameOverGameResults wrapper hidden while game over screen isn't shown
                        .add({
                            targets: gameOverGameResults,
                            easing: 'linear',
                            duration: 1,
                            opacity: [0, 1],
                        })
                        .add(animeClipReveal(gameOverTop, 'top'))
                        .add(animeClipReveal(gameOverBo, 'top'));

                Array.prototype.slice.call(gameOverGameResults.children)
                        .forEach(it => resultAnime = resultAnime
                                .add(animeClipReveal(it.firstElementChild, 'top'), '-=250')
                                .add(animeClipReveal(it.lastElementChild, 'top'), '-=250'));

                resultAnime = resultAnime.add(animeClipReveal(gameOverNextGameNotif, 'left'), '-=250');

                return resultAnime;
            };

            async function hideGameOver(instant) {
                await playAnime(gameOverAnime(), true, instant);
            }

            async function showGameOver(instant) {
                await playAnime(gameOverAnime(), false, instant);
            }

            /* Transition */
            async function playTransition(execWhenReady) {
                overlayTransitionElement.currentTime = 0;
                overlayTransitionElement.style.opacity = 1;
                await new Promise(async (resolve, reject) => {
                    overlayTransitionElement.onended = function () {
                        overlayTransitionElement.style.opacity = 0;
                        resolve();
                    };
                    try {
                        await overlayTransitionElement.play();
                        setTimeout(execWhenReady, 1000);
                    } catch (e) {
                        reject(e);
                    }
                });
            }

            /* Segment */
            /*
            config:
            {
                url: string,
                right: int?,
                left: int?,
                top: int?,
                bottom: int?,
                scale: double
            }
            */
            async function playAnimatedIcon(config) {
                animatedIconSegmentElement.style.opacity = 0;

                animatedIconSegmentVideoUrl = config.url;
                animatedIconSegmentElement.style.right = config.right != null ? (config.right + "px") : null;
                animatedIconSegmentElement.style.left = config.left != null ? (config.left + "px") : null;
                animatedIconSegmentElement.style.top = config.top != null ? (config.top + "px") : null;
                animatedIconSegmentElement.style.bottom = config.bottom != null ? (config.bottom + "px") : null;
                await tick();
                animatedIconSegmentVideoElement.currentTime = 0;
                animatedIconSegmentVideoElement.load();

                await new Promise((resolve, reject) => {
                    animatedIconSegmentVideoElement.oncanplaythrough = () => {
                        animatedIconSegmentVideoElement.style.width = config.scale * animatedIconSegmentVideoElement.videoWidth + "px";
                        animatedIconSegmentVideoElement.style.height = config.scale * animatedIconSegmentVideoElement.videoHeight + "px";

                        animatedIconSegmentVideoElement.onended = () => {
                            animatedIconSegmentElement.style.opacity = 0;
                            resolve();
                        };
                        try {
                            animatedIconSegmentVideoElement.play();
                            animatedIconSegmentElement.style.opacity = 1;
                        } catch (e) {
                            reject(e);
                        }
                    };
                });
            }

            window.playAnimatedIcon = playAnimatedIcon;

            /* Custom anime animations */
            function animeClipReveal(element, direction) {
                let transformDim;
                let insetIndex;
                let translateKey;
                if (direction === 'left') {
                    transformDim = '-100%';
                    insetIndex = 3;
                    translateKey = "translateX";
                } else if (direction === 'right') {
                    transformDim = '100%';
                    insetIndex = 1;
                    translateKey = "translateX";
                } else if (direction === 'top') {
                    transformDim = '-100%';
                    insetIndex = 0;
                    translateKey = "translateY";
                } else if (direction === 'bottom') {
                    transformDim = '100%';
                    insetIndex = 2;
                    translateKey = "translateY";
                }

                let initialInset = 'inset(';
                for (let i = 0; i < 4; i++) {
                    // +1 pixel as chrome as subpixel precision while we don't
                    if (insetIndex === i) initialInset += '100%';
                    else initialInset += '0%';
                    if (i !== 3) initialInset += ' ';
                }
                initialInset += ')';

                return {
                    targets: [element],
                    clipPath: [initialInset, 'inset(0% 0% 0% 0%)'],
                    [translateKey]: [transformDim, 0],
                    easing: 'easeOutQuint',
                    duration: 500
                };
            }

            // State stuff
            function queueStateTransition(newState) {
                if (stateMachineTransitioning) {
                    stateQueue = []; // Clear queue, only ever maintain a single queued state
                    stateQueue.push(newState);
                } else if (newState !== stateMachine.state) {
                    stateMachine.goto(newState);
                }
            }

            let closeCountdownTimeout = null;
            let closeHalftimeTimeout = null;
            let gameStartTimeout = null;

            function updateOverlayFromState() {
                if (closeCountdownTimeout != null) clearTimeout(closeCountdownTimeout);
                if (closeHalftimeTimeout != null) clearTimeout(closeHalftimeTimeout);
                if (gameStartTimeout != null) clearTimeout(gameStartTimeout);

                if (overlayState.ready && stateMachine.state === 'loading') {
                    queueStateTransition('countdown');
                } else if (stateMachine.state === 'countdown' && overlayState.showHypeVideoTime !== -1) {
                    closeCountdownTimeout = setTimeout(function () {
                        if (stateMachine.state === 'countdown')
                            queueStateTransition('hype-video');
                    }, Math.max(overlayState.showHypeVideoTime - new Date().getTime(), 1));
                } else if (stateMachine.state === 'waiting-for-teams' && overlayState.allPlayersInServer) {
                    queueStateTransition('map-bans');
                } else if (stateMachine.state === 'map-bans') {
                    if(overlayState.gameStartTime != null) {
                        setTimeout(function () {
                            queueStateTransition('game');
                        }, Math.max(overlayState.gameStartTime - new Date().getTime(), 1));
                        // TODO This is non-reversable :/
                        playAnime(revealMapBansCountdownAnime(), false, false);
                    } else if(overlayState.mapBansOver) {
                        queueStateTransition('game');
                    }
                } else if (stateMachine.state === 'game'
                        && overlayState.halftimeEndTime !== null
                        && overlayState.halftimeEndTime > new Date().getTime()) {
                    queueStateTransition('halftime');
                } else if (stateMachine.state === 'halftime' && overlayState.halftimeEndTime != null) {
                    closeHalftimeTimeout = setTimeout(function () {
                        if (stateMachine.state === 'halftime')
                            queueStateTransition('game');
                    }, Math.max(overlayState.halftimeEndTime - new Date().getTime(), 1));
                } else if (stateMachine.state === 'game' && overlayState.gameOver) {
                    queueStateTransition('game-over');
                } else if (stateMachine.state === 'game-over' && !overlayState.gameOver) {
                    queueStateTransition('game');
                }
            }

            // Websocket stuff
            let initialInfo = true;

            function initWs() {
                let socket;
                try {
                    socket = new WebSocket(WS_URL);
                } catch (e) {
                    console.error("WS open error:", e);
                    initWs();
                }

                socket.addEventListener('open', function (event) {
                    socket.send(JSON.stringify({
                        type: "greeting",
                        clientType: "overlay"
                    }));
                });

                // Listen for messages
                socket.addEventListener('message', async function (event) {
                    overlayState = JSON.parse(event.data);
                    overlayState.ready = true;
                    if (overlayState.mapBansStartTime === -1) {
                        overlayState.showHypeVideoTime = -1;
                    } else {
                        overlayState.showHypeVideoTime = overlayState.mapBansStartTime - hypeVideoElement.duration * 1000;
                    }

                    if (initialInfo) {
                        await tick();
                        initialInfo = false;

                        // Leave all screens on first information
                        hideCountdown(true);
                        hideHypeVideo(true);
                        hideWaitingForTeams(true);
                        hideMapBans(true);
                        playAnime(revealMapBansCountdownAnime(), true, true);
                        hideGame(true);
                        hideHalftime(true);
                        hideHalftimeVideo(true);
                        hideGameOver(true);
                        hideGameOverVideo(true);

                        beginCountdownMusic();
                    }

                    updateOverlayFromState();

                    // For debugging
                    window.overlayStateMachine = stateMachine;
                    window.overlayState = overlayState;
                    window.updateOverlayState = () => overlayState = overlayState;
                });

                socket.addEventListener('error', function (event) {
                    console.error("WS error:", event);
                    socket.close();
                });

                socket.addEventListener('close', function (event) {
                    initWs();
                });
            }

            function onBeforeTransition() {
                stateMachineTransitioning = true;
            }

            function onAfterTransition() {
                // Wait 500ms for other lifecycle events to get called
                setTimeout(function () {
                    if (stateQueue.length > 0) {
                        stateMachineTransitioning = false;

                        let newState = stateQueue.shift();
                        if (newState !== stateMachine.state)
                            stateMachine.goto(newState);
                    } else {
                        stateMachineTransitioning = false;
                        updateOverlayFromState();
                    }
                }, 500);
            }

            requestAnimationFrame(function () {
                // Init overlay on next screen
                requestAnimationFrame(function () {
                    // Wait for hype video duration to be ready
                    onHypeVideoDurationReady(function () {
                        // Init WS as early as possible
                        initWs();
                    });
                });
            });

            stateMachine = new StateMachine({
                init: 'loading',
                transitions: [
                    /* Real transitions, for reference
                    {name: 'openCountdown', from: 'loading', to: 'countdown'},
                    {name: 'closeCountdown', from: 'countdown', to: 'loading'},
                    {name: 'openHypeVideo', from: 'countdown', to: 'hype-video'},
                    {name: 'openWaitingForTeams', from: 'hype-video', to: 'waiting-for-teams'},
                    {name: 'openMapBans', from: ['waiting-for-teams', 'hype-video'], to: 'map-bans'},
                    {name: 'openGame', from: ['map-bans', 'halftime', 'game-over'], to: 'game'},
                    {name: 'openHalftime', from: 'game', to: 'halftime'},
                    {name: 'openGameOver', from: ['game', 'halftime'], to: 'game-over'}
                     */

                    /* Fake transitions, used in prod to avoid bugs */
                    {name: 'openCountdown', from: '*', to: 'countdown'},
                    {name: 'closeCountdown', from: '*', to: 'loading'},
                    {name: 'openHypeVideo', from: '*', to: 'hype-video'},
                    {name: 'openWaitingForTeams', from: '*', to: 'waiting-for-teams'},
                    {name: 'openMapBans', from: '*', to: 'map-bans'},
                    {name: 'openGame', from: '*', to: 'game'},
                    {name: 'openHalftime', from: '*', to: 'halftime'},
                    {name: 'openGameOver', from: '*', to: 'game-over'},

                    /* GOTO */
                    {
                        name: 'goto', from: '*', to: function (s) {
                            return s
                        }
                    }
                ],
                methods: {
                    onLeaveLoading: hideLoading,
                    onEnterCountdown: async function() {
                        await showCountdown();
                        if(socialMediaInterval != null) {
                            clearInterval(socialMediaInterval);
                            socialMediaInterval = null;
                        }
                        socialMediaInterval = setInterval(() => {
                            const socialIconConfig = SOCIAL_ICONS_COUNTDOWN_LEFT[socialIconLeftRotation];
                            socialIconLeftRotation = (socialIconLeftRotation + 1) % SOCIAL_ICONS_LEFT.length;
                            playAnimatedIcon(socialIconConfig);
                        }, 30000);
                    },
                    onLeaveCountdown: async function() {
                        if(socialMediaInterval != null) {
                            clearInterval(socialMediaInterval);
                            socialMediaInterval = null;
                        }
                        //await hideCountdown();
                    },
                    onEnterHypeVideo: async function () {
                        //await stopCountdownMusic();
                        playVideo(hypeVideoElement).then(() => queueStateTransition('waiting-for-teams'));
                        await showHypeVideo();
                        countdownElement.style.opacity = 0;
                    },
                    onLeaveHypeVideo: async function () {
                        hypeVideoElement.pause();
                        beginCountdownMusic();
                        await hideHypeVideo();
                    },
                    onEnterWaitingForTeams: showWaitingForTeams,
                    onLeaveWaitingForTeams: hideWaitingForTeams,
                    onEnterMapBans: showMapBans,
                    //onLeaveMapBans: hideMapBans,
                    onEnterGame: async function () {
                        stopCountdownMusic();
                        await playTransition(() => {
                            showGame(true);
                            hideMapBans(true);
                            hideGameOver(true);
                            hideHalftime(true);

                            // Prep social icon to play after caster or immediately if no caster
                            const socialIconConfig = SOCIAL_ICONS_CSGO_LEFT[socialIconLeftRotation];
                            socialIconLeftRotation = (socialIconLeftRotation + 1) % SOCIAL_ICONS_LEFT.length;

                            // Play caster thing
                            const casterIconConfig = CASTER_ICONS[overlayState.casterSteamId];
                            setTimeout(async () => {
                                if(casterIconConfig != null) {
                                    await playAnimatedIcon(casterIconConfig);
                                    setTimeout(() => {
                                        playAnimatedIcon(socialIconConfig);
                                    }, 10000);
                                } else {
                                    playAnimatedIcon(socialIconConfig);
                                }
                            }, 10000);
                        });
                    },
                    onLeaveGame: async function () {
                        beginCountdownMusic();
                        await new Promise(async (resolve, reject) => {
                            playTransition(() => {
                                hideGame(true);
                                resolve();
                            });
                        });
                    },
                    onEnterHalftime: async function() {
                        showHalftime(true);
                        if(overlayState.htVideoEnabled && halftimeVideoElement != null) {
                            await stopCountdownMusic();
                            setTimeout(() => {
                                playVideo(halftimeVideoElement).then(() => {
                                    beginCountdownMusic();
                                    hideHalftimeVideo();
                                });
                                showHalftimeVideo();
                            }, 3000);
                        }
                    },
                    /*onLeaveHalftime: async function() {
                        await Promise.all([hideHalftime()*//*, hideHalftimeVideo()*//*]);
                    },*/
                    onEnterGameOver: async function() {
                        showGameOver(true);
                        // The 'some' check is to ensure there is still a queued game
                        if(gameOverVideoElement != null
                                && overlayState.gameResults.some(it =>
                                        it.team1Score < 0 && it.team2Score < 0)) {
                            await stopCountdownMusic();
                            setTimeout(() => {
                                playVideo(gameOverVideoElement).then(() => {
                                    beginCountdownMusic();
                                    hideGameOverVideo();
                                });
                                showGameOverVideo();
                            }, 3000);
                        }
                    },
                    /*onLeaveGameOver: async function() {
                        await Promise.all([hideGameOver()*//*, hideGameOverVideo()*//*]);
                    },*/
                    onBeforeTransition,
                    onAfterTransition
                }
            });
        }, 3000);
    });

    let showDebugMenu = false;
    function toggleDebugMenu(event) {
        if(event.key === '`') {
            showDebugMenu = !showDebugMenu;
        }
    }
</script>

<svelte:head>
    <!-- Inject global styles -->
    <style>
        /* Overlay webfonts */
        @font-face{
            font-family:"Furore";
            src: url("fonts/other/furore/Furore-webfont.woff2") format("woff2"),
            url("fonts/other/furore/Furore-webfont.eot") format("eot"),
            url("fonts/other/furore/Furore-webfont.woff") format("woff"),
            url("fonts/other/furore/Furore-webfont.ttf") format("truetype");
        }

        html, body, #app_main, #app_main > .router-view {
            font-family:"Furore";
            font-size: 24pt;
            overflow: hidden;
            height: 100vh;
            width: 100vw;
            max-height: 100vh;
            max-width: 100vw;
            min-height: 100vh;
            min-width: 100vw;
            margin: 0;
            padding: 0;
            background-color: transparent;
            /*background-color: red; !* TODO For debugging *!*/
        }
    </style>
</svelte:head>

<style>
    .overlay-wrapper {
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .overlay-countdown {
        height: 100%;
    }

    .overlay-center {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 0 auto;
    }

    .overlay-card {
        display: inline-block;
        background-color: white;
        padding: 0.25em 0.5em;
    }

    .overlay-card-black {
        color: white;
        background-color: black;
    }
    .overlay-card-yellow {
        color: black;
        background-color: yellow;
    }
    .overlay-card-orange {
        color: black;
        background-color: #ff7300;
    }
    .overlay-card-cyan {
        color: black;
        background-color: cyan;
    }
    .overlay-card-transparent {
        background-color: transparent;
    }
    .background-color-white {
        background-color: white;
    }
    .display-inline-block {
        display: block;
    }
    .display-block {
        display: block;
    }
    .overlay-screen {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    /** COUNTDOWN **/
    .countdown-timer {
        font-size: 1.5em;
        transform-origin: top;
    }
    .overlay-countdown-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .overlay-countdown-song-wrapper {
        display: flex;
        flex-grow: 1;
        background-color: white;
        color: black;
        justify-content: space-between;
    }
    .overlay-countdown-song-name-wrapper {
        position: relative;
        height: 100%;
        flex-grow: 1;
    }
    .overlay-countdown-song-name {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 0.5em 0;
        font-size: 2em;
        white-space: nowrap;
        text-align: left;
    }
    .overlay-countdown-shim {
        width: 1em;
        height: 100%;
        background: linear-gradient(270deg, transparent, rgba(255,255,255,0.4) 0.25em,rgba(255,255,255,0.8) 0.5em,rgba(255,255,255,1) 0.9em);
        z-index: 100;
    }
    .overlay-countdown-soundcloud {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding-right: 1em;
        padding-left: 2em;
        color: black;
        background: linear-gradient(90deg, transparent, rgba(255,255,255,0.4) 0.5em,rgba(255,255,255,0.8) 1em,rgba(255,255,255,1) 1.5em);
        z-index: 100;
    }
    .overlay-countdown-logo {
        background-color: black;
        color: white;
        padding: 0.4em 0.5em;
        padding-right: 0.4em; /* Logo right feels empty */
        font-size: 2.5em;
    }

    /** TRANSITION **/
    .overlay-transition,
    .overlay-video,
    .overlay-halftime-video,
    .overlay-game-over-video {
        z-index: 10000;
    }
    .overlay-background > video,
    .overlay-transition > video,
    .overlay-video > video,
    .overlay-halftime-video > video,
    .overlay-game-over-video > video {
        height: 100%;
        width: 100%;
        object-fit: fill;
    }

    /** LOADING **/
    .overlay-loading {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10000000;
        background-color: #101010;
    }
    .overlay-loading-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /** WAITING FOR TEAMS **/
    .overlay-waiting-for-teams {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 2em;
    }
    .overlay-waiting-for-teams-bottom {
        transform-origin: top;
    }

    /** MAP BANS **/
    .overlay-map-bans {
        display: flex;
        flex-direction: column;
    }
    .overlay-map-bans-cards-wrapper {
        position: relative;
        flex-grow: 1;
        width: 100%;
    }
    .overlay-map-bans-cards {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 0.45em;
        perspective: 1000px;
    }
    .overlay-map-bans-card {
        transform-style: preserve-3d;
        transform: rotateY(0deg);
        opacity: 0;
    }
    .overlay-map-bans-countdown-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    /** HALFTIME **/
    .overlay-halftime {
        font-size: 2em;
    }
    .overlay-halftime-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .overlay-halftime-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    .overlay-halftime-center-score {
        font-size: 4em;
        margin-top: -11rem;
        color: white;
    }
    .overlay-halftime-center-score {
        margin-top: 5rem;
    }
    .overlay-halftime-center-timer {
        margin-top: 6rem;
    }

    /** GAME OVER **/
    .overlay-game-over {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 2em;
    }
    .overlay-game-over-top {
        font-size: 2em;
        padding: 0.25em 0.5em;
        line-height: 1em;
    }
    .overlay-game-over-bottom {
        display: flex;
        flex-direction: row;
        justify-content: center;
        line-height: 1em;
    }
    .overlay-game-over-bottom-games {
        display: flex;
        flex-direction: column;
    }
    .overlay-game-over-bottom-game {
        display: flex;
        flex-direction: row;
    }
    .overlay-game-over-bottom-game-name {
        width: 330px;
        text-align: left;
    }
    .overlay-game-over-bottom-game-score {
        width: 320px;
        text-align: center;
    }
    .overlay-game-over-bottom-bo {
        height: 1.5em;
    }
    .overlay-game-over-next-game-notif {
        visibility: hidden;
        margin-top: 0.5em;
    }
    .overlay-game-over-next-game-notif-visible {
        visibility: visible;
    }

    /** SEGMENTS **/
    .overlay-segment {
        position: fixed;
    }
    .overlay-animated-icon > video {
        object-fit: fill;
    }

    .overlay-csgo-logos {
        z-index: -1;
        bottom: 0;
        transform: translateX(-50%);
        margin-bottom: 0.25em;
    }

    /** DEBUG **/
    .overlay-debug {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 99999999;
        background: rgba(255,255,255,0.5);
        font-family: sans-serif;
        font-size: 12pt;
    }

    .overlay-debug-fake-bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -999999999;
        background-image: url("https://s3.wasabisys.com/oef/automation/overlay/images/debug_bg.jpg");
        background-size: cover;
    }
</style>

<svelte:body on:keydown={toggleDebugMenu}/>

<Time bind:time={now} updateInterval={500} />

{#if DEBUG}
    {#if showDebugMenu}
        <div class="overlay-debug" transition:fly={{duration: 250, y: -250}}>
            <h1 class="ui header">DEBUG MENU</h1>
        </div>
    {/if}

    <div class="overlay-debug-fake-bg"></div>
{/if}

<div class="overlay-wrapper">
    <div class="overlay-screen overlay-background" bind:this={backgroundVideo}>
        <video muted autoplay loop>
            <source src="https://oef.s3.wasabisys.com/automation/overlay/background.mp4" type="video/mp4">
        </video>
    </div>

    <div class="overlay-screen overlay-video">
        <video bind:this={hypeVideoElement} on:loadedmetadata={notifyHypeVideoMetaReady}>
<!--            <source src="https://s3.wasabisys.com/oef/automation/overlay/playoffs-intro.mp4" type="video/mp4">-->
            <source src="https://s3.wasabisys.com/oef/automation/overlay/finals.mp4" type="video/mp4">
        </video>
    </div>
    <div class="overlay-screen overlay-transition">
        <video bind:this={overlayTransitionElement}>
            <source src="https://s3.wasabisys.com/oef/automation/overlay/transition.webm" type="video/webm">
        </video>
    </div>
    <!--<div class="overlay-transition">
        <video>
            <source src="/video/overlay/transition-a.webm" type="video/webm">
        </video>
        <video>
            <source src="/video/overlay/transition-b.webm" type="video/webm">
        </video>
    </div>-->

    <div class="overlay-screen overlay-loading" bind:this={overlayLoadingElement}>
        <img class="overlay-loading-logo" style="height: 3em" src="/images/overlay/LogoLight.svg"/>
    </div>

    <div class="overlay-segment overlay-animated-icon" bind:this={animatedIconSegmentElement}>
        <video bind:this={animatedIconSegmentVideoElement}>
            <source src={animatedIconSegmentVideoUrl} type="video/webm">
        </video>
    </div>

    <div class="overlay-screen overlay-countdown" bind:this={countdownElement}>
        <div class="overlay-center">
            <div class="display-inline-block" bind:this={countdownHeaderLeft}>
                <div class="overlay-card">{overlayState.tournamentName}</div>
            </div>
            <div>
                <div class="overlay-card overlay-card-black display-block" bind:this={countdownHeaderCenter}>
                    {overlayState.team1Name} vs {overlayState.team2Name}
                </div>
                <div class="overlay-card overlay-card-yellow display-block countdown-timer" bind:this={countdownHeaderTimer}>
                    {formatDuration(Math.max(overlayState.showHypeVideoTime - now, 0))}
                </div>
            </div>
            <div class="display-inline-block" bind:this={countdownHeaderRight}>
                <div class="overlay-card">{overlayState.tournamentLayout}</div>
            </div>
        </div>
        <div class="overlay-center overlay-countdown-bottom">
            <div class="overlay-countdown-song-wrapper" bind:this={countdownFooterSongWrapper}>
                <div class="overlay-countdown-shim"></div>
                <div class="overlay-countdown-song-name-wrapper" bind:this={countdownSongNameWrapper}>
                    <div class="overlay-countdown-song-name countdown-song-name-a" bind:this={oldCountdownSongName}>
                        {oldCountdownAudioTitle}
                    </div>
                    <div class="overlay-countdown-song-name countdown-song-name-b" bind:this={newCountdownSongName}>
                        {newCountdownAudioTitle}
                    </div>
                </div>
                <div class="overlay-countdown-soundcloud">
                    <img style="height: 2em" src="/images/overlay/soundcloud.svg"/>
                </div>
            </div>
            <div class="overlay-countdown-logo" bind:this={countdownFooterLogo}>
                <img style="height: 0.8em" src="/images/overlay/LogoLight.svg"/>
            </div>
        </div>
    </div>

    <div class="overlay-screen overlay-waiting-for-teams">
        <div class="overlay-waiting-for-teams-top">
            <div class="overlay-card overlay-card-black overlay-waiting-for-teams-team-names" bind:this={waitingForTeamsTeamNames}>
                {overlayState.team1Name} vs {overlayState.team2Name}
            </div><div class="overlay-card overlay-waiting-for-teams-bo" bind:this={waitingForTeamsBo}>
            BO{overlayState.bo}
        </div>
        </div>
        <div class="overlay-card overlay-card-orange overlay-waiting-for-teams-bottom" bind:this={waitingForTeamsBottom}>
            Waiting for teams...
        </div>
    </div>

    <div class="overlay-screen overlay-map-bans">
        <div class="overlay-center">
            <div class="display-inline-block" bind:this={mapBansHeaderLeft}>
                <div class="overlay-card">{overlayState.tournamentName}</div>
            </div>
            <div>
                <div class="overlay-card overlay-card-black display-block" bind:this={mapBansHeaderCenter}>
                    {overlayState.team1Name} vs {overlayState.team2Name}
                </div>
                <div class="overlay-card overlay-card-cyan display-block countdown-timer" bind:this={mapBansHeaderBottom}>
                    Map Bans
                </div>
            </div>
            <div class="display-inline-block" bind:this={mapBansHeaderRight}>
                <div class="overlay-card">{overlayState.tournamentLayout}</div>
            </div>
        </div>
        <div class="overlay-map-bans-cards-wrapper">
            <div class="overlay-map-bans-cards" bind:this={mapBansCards}>
                {#each Object.entries(overlayState.maps) as map}
                    <div class="overlay-map-bans-card">
                        <MapCard
                                mapName={map[1]}
                                map={map[0]}
                                state={overlayState.mapStatus[map[0]] != null ? overlayState.mapStatus[map[0]] : STATE_MAP_NOT_READY} />
                    </div>
                {/each}
            </div>
            <div class="overlay-map-bans-countdown-wrapper">
                <div class="overlay-card overlay-card-orange overlay-map-bans-countdown" bind:this={mapBansCountdown}>
                    Game starting in {formatDuration(Math.max(overlayState.gameStartTime - now, 0))}
                </div>
            </div>
        </div>
    </div>

    {#if overlayState.config.halftimeVideo !== ""}
        <div class="overlay-screen overlay-halftime-video">
            <video bind:this={halftimeVideoElement}>
                <source src={overlayState.config.halftimeVideo} type="video/mp4">
            </video>
        </div>
    {/if}
    <div class="overlay-screen overlay-halftime">
        <div class="overlay-halftime-top">
            <div class="overlay-halftime-left">
                <div class="overlay-halftime-left-text overlay-card" bind:this={halftimeLeftText}>
                    { overlayState.otIndex === 0 ? "Halftime" : `OT ${overlayState.otIndex}` }
                </div><div class="overlay-halftime-left-logo overlay-card overlay-card-black" bind:this={halftimeLeftLogo}>
                    <img style="height: 0.7em" src="/images/overlay/LogoLight.svg"/>
                </div>
            </div>
            <div class="overlay-halftime-right overlay-card" bind:this={halftimeRight}>
                BO{overlayState.bo} - Game {overlayState.boGame}
            </div>
        </div>
        <div class="overlay-halftime-center">
            <div class="overlay-halftime-center-team-names overlay-card overlay-card-black" bind:this={halftimeTeamNames}>
                {overlayState.team1Name} vs {overlayState.team2Name}
            </div>
            <div class="overlay-halftime-center-score" bind:this={halftimeScore}>
                {overlayState.team1Score} - {overlayState.team2Score}
            </div>
            <div class="overlay-halftime-center-timer overlay-card overlay-card-yellow" bind:this={halftimeTimer}>
                {formatDuration(Math.max(overlayState.halftimeEndTime - now, 0))}
            </div>
        </div>
    </div>

    {#if overlayState.config.gameOverVideo !== ""}
        <div class="overlay-screen overlay-game-over-video">
            <video bind:this={gameOverVideoElement}>
                <source src={overlayState.config.gameOverVideo} type="video/mp4">
            </video>
        </div>
    {/if}
    <div class="overlay-screen overlay-game-over">
        <div class="overlay-card overlay-card-black overlay-game-over-top" bind:this={gameOverTop}>
            {winningTeamText(overlayState)}
        </div>
        <div class="overlay-game-over-bottom">
            <div class="overlay-card overlay-game-over-bottom-bo" bind:this={gameOverBo}>
                BO{overlayState.bo}
            </div>
            <div class="overlay-game-over-bottom-games" bind:this={gameOverGameResults}>
                <!-- TODO Loop -->
                {#each overlayState.gameResults as gameResult, index}
                    <div class="overlay-game-over-bottom-game">
                        <div class="overlay-card overlay-game-over-bottom-game-name">
                            Game {index + 1}
                        </div>
                        <div class="overlay-card overlay-card-black overlay-game-over-bottom-game-score">
                            {gameResult.team1Score < 0 ? '?' : gameResult.team1Score}
                            -
                            {gameResult.team2Score < 0 ? '?' : gameResult.team2Score}
                        </div>
                    </div>
                {/each}
            </div>
        </div>
        <div class="overlay-card overlay-card-cyan overlay-game-over-next-game-notif"
             class:overlay-game-over-next-game-notif-visible={overlayState.hasNextGame}
             bind:this={gameOverNextGameNotif}>
            Next game starts in {formatDuration(Math.max(overlayState.nextGameStartTime - now, 0))}!
        </div>
    </div>

    <img class="overlay-segment overlay-csgo-logos"
         src="https://s3.wasabisys.com/oef/automation/overlay/images/graphic1.png" />
</div>
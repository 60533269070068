<script>
    import {Link} from 'svelte-routing';
    import Giveaway from "../../../giveaway/Giveaway.svelte";

    let giveawayLinkInput = null;
    async function copyText(input) {
        if(input != null) {
            input.select();
        }
        document.execCommand("copy");
    }

    let giveaway = {
        title: "My Epic Giveaway",
        actionDesc: "Get entries in the giveaway by performing the actions below:",
        prizeBlurb: "**Giveaway prize:** An amazing prize",
        endDate: 0
    };
</script>

<style>
    .giveaway-preview {
        background: #545454;
    }
    .giveaway-preview-content {
        padding-top: 5em;
    }
    .giveaway-editor {
        margin-top: 1em;
    }
</style>

<div class="ui container">
    <h1 class="ui inverted header">Edit giveaway</h1>
    <div class="ui inverted form">
        <div class="field ui inverted segment">
            <label>Giveaway invite link</label>
            <div class="ui action input">
                <input type="text" value={"TODO REPLACE ME"} bind:this={giveawayLinkInput} readonly>
                <button class="ui teal right labeled icon button" on:click={() => copyText(giveawayLinkInput)}>
                    <i class="copy icon"></i>
                    Copy
                </button>
            </div>
        </div>
    </div>
</div>
<div class="ui stackable grid container giveaway-editor">
    <div class="eight wide column">
        <div class="ui inverted form">
            <div class="field">
                <label>Name</label>
                <input type="text"
                       placeholder="My Epic Giveaway"
                       bind:value={giveaway.title}>
            </div>
            <div class="field">
                <label>Action description</label>
                <input type="text"
                       placeholder="Get entries in the giveaway by performing the actions below:"
                       bind:value={giveaway.actionDesc}>
            </div>
            <div class="field">
                <label>Prize blurb</label>
                <input type="text"
                       placeholder="**Giveaway prize:** An amazing prize"
                       bind:value={giveaway.prizeBlurb}>
            </div>
            <button class="ui primary button">Save</button>
            <Link to="/panel/admin/giveaways">
                <button class="ui button">Cancel</button>
            </Link>
        </div>
    </div>
    <div class="eight wide column">
        <div class="ui segment giveaway-preview">
            <div class="giveaway-preview-content">
                <Giveaway/>
            </div>
            <div class="ui top attached label black">Giveaway preview</div>
        </div>
    </div>
</div>
<script>
    import { Router, Route, links, navigate } from "svelte-routing";
    import TeamsPanel from "./teams/TeamsPanel.svelte";
    import ProfilePanel from "./profile/ProfilePanel.svelte";
    import AdminPanel from "./admin/AdminPanel.svelte";
    import Redirect from "../util/Redirect.svelte";
    import { fade, fly } from "svelte/transition";
    import Cookies from "js-cookie";
    import {apiGet} from "../api";
    import {ADMIN_PERM_LEVEL, COOKIE_WREL_SESSION} from "../common";
    import {onDestroy} from "svelte";

    export let nestedPath;

    let section = "";
    let splitSection = "";
    $: splitSection = nestedPath.split("/");
    $: {
        if(splitSection.length > 0) {
            section = splitSection[0];
        } else {
            section = "";
        }
    }

    async function doLogout() {
        Cookies.remove(COOKIE_WREL_SESSION);
        navigate('/auth/login');
    }

    // Query profile
    let profile = null;
    async function updateProfile() {
        let result = await apiGet('/profile');
        if(result.success) {
            profile = result.data;
        } else {
            console.error("Could not query profile info!", result.error);
        }
    }
    updateProfile();

    let profileTab = null;

    let dropDownElement = null;
    let dropDownJq = null;
    $: if(dropDownElement != null && dropDownJq == null) {
        dropDownJq = jQuery(dropDownElement).dropdown({
            action: 'nothing'
        });
    }

    let adminActive = false;
    $: adminActive = splitSection[0] === 'admin' && splitSection.length >= 2;
</script>

<style>
    .page-content {
        padding-top: 5em;
    }
</style>

{#if splitSection.length <= 1 || (adminActive && splitSection.length <= 2)}
<div class="ui large top fixed menu inverted" use:links transition:fade="{{duration: 250}}">
    <a class="item" href="/"><b>OEFLink</b></a>
    <a class="item" href="/panel/teams" class:active={section === "teams"}>My Teams</a>
    <a class="item" href="/panel/profile" class:active={section === "profile"} bind:this={profileTab}>Profile</a>
    <div class="right menu">
        {#if profile != null && profile.perms >= ADMIN_PERM_LEVEL}
            <div class="ui orange inverted icon dropdown link item"
                 bind:this={dropDownElement}
                 in:fly={{y: -50, duration: 500}}>
                <!--
                Doesn't fit on mobile:

                <span class="text">ADMIN MENU</span>
                <i class="dropdown icon"></i>-->
                <i class="wrench icon"></i>
                <div class="menu" use:links>
                    <div class="header">
                        <i class="tools icon"></i>
                        Admin menu
                    </div>
                    <a class="item"
                       href="/panel/admin/broadcast"
                       class:active={adminActive && splitSection[1] === "broadcast"}>
                        Send broadcast
                    </a>
                    <a class="item"
                       href="/panel/admin/giveaways"
                       class:active={adminActive && splitSection[1] === "giveaways"}>
                        Giveaways
                    </a>
                    <a class="item"
                       href="/panel/admin/overlay"
                       class:active={adminActive && splitSection[1] === "overlay"}>
                        Overlay
                    </a>
                    <!--<div class="item">
                        <i class="dropdown icon"></i>
                        <span class="text">New</span>
                        <div class="menu">
                            <div class="item">Document</div>
                            <div class="item">Image</div>
                        </div>
                    </div>
                    <div class="item">
                        Open...
                    </div>
                    <div class="item">
                        Save...
                    </div>
                    <div class="item">Edit Permissions</div>
                    <div class="divider"></div>
                    <div class="header">
                        Export
                    </div>
                    <div class="item">
                        Share...
                    </div>-->
                </div>
            </div>
        {/if}
        <a class="item" on:click={doLogout}>
            <i class="icon fas fa-sign-out"></i>
            Logout
        </a>
    </div>
</div>
{/if}

<div class="page-content">
    <Router>
        <Route path="teams/*">
            <TeamsPanel profileTab={profileTab}/>
        </Route>
        <Route path="profile/*" component={ProfilePanel} />
        <Route path="admin/*" component={AdminPanel} />
    </Router>
</div>
<script>
    import {
        STATE_MAP_READY,
        STATE_MAP_NOT_READY,
        STATE_MAP_BANNED_BY_OTHER,
        STATE_MAP_BANNED_BY_SELF,
        STATE_MAP_CHOSEN,
        STATE_MAP_READY_CHOOSE
    } from "./MapCardState";
    import {navigate} from "svelte-routing";
    import Cookies from "js-cookie";
    import {scale, fade, slide} from 'svelte/transition';
    import {sineIn, sineOut} from 'svelte/easing';
    import MapCard from "./MapCard.svelte";
    import SideCard from "./SideCard.svelte";
    import Time from "../components/util/Time.svelte";
    import anime from "animejs";
    import {COOKIE_WREL_SESSION, WS_URL} from "../common";
    import {onMount} from "svelte";

    const STATE_LOADING = -1;
    const STATE_BAN_SELF = 0; // Waiting for user to ban map
    const STATE_CHOOSE_SELF = 1; // Waiting for user to choose map
    const STATE_BAN_OTHER = 2; // Waiting for other team to ban map
    const STATE_CHOOSE_SIDE = 3; // Waiting for user to choose side
    const STATE_DONE = 4; // Map bans done

    let curWs = null;

    let team0 = "Team A";
    let team1 = "Team B";

    let state = STATE_LOADING;

    let maps = {};

    let mapStatus = {
        // "de_lake": STATE_MAP_BANNED_BY_OTHER
    };

    let showLoader = false;

    let currentlyBanningMap = null;
    let currentlyBanningMapName = null;

    let currentlyPickingForMapName = null;

    let banResults = [];
    let gameStartTime = 0;

    let boSize = 1;

    function confirmBanMap(map, mapName) {
        if (state === STATE_BAN_SELF || state === STATE_CHOOSE_SELF) {
            currentlyBanningMap = map;
            currentlyBanningMapName = mapName;
        }
    }

    function cancelBanMap() {
        currentlyBanningMap = null;
        currentlyBanningMapName = null;
    }

    function banMap(map) {
        showLoader = true;
        if(curWs != null) {
            curWs.send(JSON.stringify({
                type: state === STATE_BAN_SELF ? "ban-map" : "pick-map",
                map
            }));
        }
    }

    let currentlyChoosingSide = null;
    let currentlyChoosingSideName = null;

    function confirmChooseSide(side, sideName) {
        currentlyChoosingSide = side;
        currentlyChoosingSideName = sideName;
    }

    function cancelChooseSide() {
        currentlyChoosingSide = null;
        currentlyChoosingSideName = null;
    }

    function chooseSide(side) {
        showLoader = true;
        if(curWs != null) {
            curWs.send(JSON.stringify({
                type: "choose-side",
                side
            }));
        }
    }

    let now = new Date().getTime();

    let secondsElement = null;
    let secondsAnime = null;
    $: if(secondsElement != null) {
        secondsAnime = anime({
            targets: [secondsElement],
            keyframes: [
                {scale: 1, color: 'rgb(255,0,0)', duration: 10},
                {scale: 1.15, color: 'rgb(255,0,0)', duration: 100},
                {scale: 1, color: 'rgb(255,255,255)', duration: 250}
            ],
            easing: 'linear'
        });
    }

    let lastSecondsUntilNextGame = 0;
    let secondsUntilNextGame;
    $: secondsUntilNextGame = Math.max(Math.round((gameStartTime - now) / 1000), 0);
    $: if(secondsUntilNextGame !== lastSecondsUntilNextGame && secondsAnime != null) {
        lastSecondsUntilNextGame = secondsUntilNextGame;
        secondsAnime.pause();
        secondsAnime.seek(0);
        secondsAnime.play();
    }

    $: if(state !== STATE_BAN_SELF && state !== STATE_CHOOSE_SELF) {
        currentlyBanningMap = null;
        currentlyBanningMapName = null;
    }

    $: if(state !== STATE_CHOOSE_SIDE) {
        currentlyChoosingSide = null;
        currentlyChoosingSideName = null;
    }

    function initWs() {
        let socket;
        try {
            socket = new WebSocket(WS_URL);
        } catch (e) {
            console.error("WS open error:", e);
            initWs();
        }

        socket.addEventListener('open', function (event) {
            socket.send(JSON.stringify({
                type: "greeting",
                clientType: "map-bans",
                auth: Cookies.get(COOKIE_WREL_SESSION)
            }));
        });

        // Listen for messages
        socket.addEventListener('message', async function (event) {
            if(event.data === 'UNAUTHORIZED') {
                navigate(`/auth/login?redirect=${encodeURIComponent(window.location.href)}`, {replace:true});
            } else {
                let incomingData = JSON.parse(event.data);

                team0 = incomingData.team0;
                team1 = incomingData.team1;

                state = incomingData.state;

                mapStatus = incomingData.mapStatus;
                maps = incomingData.maps;

                banResults = incomingData.banResults;
                gameStartTime = incomingData.gameStartTime;

                currentlyPickingForMapName = incomingData.currentlyPickingForMapName;

                boSize = incomingData.boSize;

                showLoader = false;
            }
        });

        socket.addEventListener('error', function (event) {
            console.error("WS error:", event);
            socket.close();
        });

        socket.addEventListener('close', function (event) {
            initWs();
        });
        curWs = socket;
    }
    onMount(initWs);

    const MODAL_IN_TRANSITION = {duration: 200, start: 1.5, opacity: 0, easing: sineOut};
    const MODAL_OUT_TRANSITION = {duration: 200, start: 1.5, opacity: 0, easing: sineIn};

    const TURN_IN_TRANSITION = {duration: 200, start: 1.5, opacity: 0, easing: sineOut};
    const TURN_OUT_TRANSITION = {duration: 200, start: 0.5, opacity: 0, easing: sineIn};

    // TODO Remove
    window.debugMapBans = function (newState) {
        state = newState;
        gameStartTime = now + 60 * 1000;
    };
</script>
<svelte:head>
    <style>
        /* Map-bans webfonts */
        @font-face{
            font-family:"Furore";
            src: url("fonts/other/furore/Furore-webfont.woff2") format("woff2"),
            url("fonts/other/furore/Furore-webfont.eot") format("eot"),
            url("fonts/other/furore/Furore-webfont.woff") format("woff"),
            url("fonts/other/furore/Furore-webfont.ttf") format("truetype");
        }

        html, body {
            font-family: "Furore", sans-serif;
            color: white;
            user-select: none;
        }
    </style>
</svelte:head>
<style>
    .page-wrapper {
        display: flex;
        flex-direction: column;
        background-image: url("/images/map-bans/backgrounds/background.png");
        background-position: 50% 50%;
        background-size: cover;
        margin: 0;
        padding: 0;
        min-height: 100%;
        width: 100%;
    }

    /* HEADER */
    .header {
        display: flex;
        width: 100%;
        font-size: 3em;
        flex-direction: row;
        line-height: 1em; /* FUCK THIS FONT MAN */
        flex-wrap: wrap;
        padding: 0.3em 0;
        background-color: rgba(255,255,255,0.1);
    }
    .header-left {
        flex-grow: 9999;
    }
    .header-right {
        flex-grow: 1;
        text-align: center;
    }
    .header-left, .header-right {
        padding: 0.1em 0.6em;
    }

    /* INDICATOR */
    .indicator {
        position: relative;
        font-size: 3em;
        text-align: center;
        line-height: 1em;
    }
    .indicator-primary {
        /* Bug in chrome causes page to go blank when animated indicator is enabled*/
        /*position: absolute;
        top: 0;
        left: 0;
        width: 100%;*/
    }
    .indicator-primary, .indicator-primary-placeholder {
        display: block;
        padding: 0.6em;
    }
    .indicator-primary-placeholder {
        visibility: hidden;
    }
    .indicator-primary.green {
        color: #6dff6d;
        text-shadow: 0 0 10px #41ff0066, 0 0 20px #41ff00b5, 0 0 30px #41ff00, 0 0 40px #41ff00, 0 0 50px #41ff00;
    }
    .indicator-primary.red {
        color: red;
        text-shadow: 0 0 10px #ff000066, 0 0 20px #ff0000b5, 0 0 30px #ff0000;
    }

    .map-cards {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    /* MODAL */
    .modal-background {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
    }
    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2C2C2C;
        font-size: 2rem;
        border: solid 1px #414141;
    }
    .modal-title {
        background-color: #252525;
        padding: 0.75em;
        opacity: 0.87;
    }
    .modal-body {
        font-size: 0.5em;
        padding: 1.75em;
    }
    .modal-actions {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-top: 2em;
    }
    .modal-action {
        font-weight: bold;
        font-size: 1.25em;
        padding: 0.5em;
        margin-left: 0.25em;
        transition: 0.25s background-color;
        border-radius: 0.1em;
    }
    .modal-action:hover {
        background-color: #272727;
        cursor: pointer;
    }

    /* Results */
    .finished {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        margin: 0 auto;
    }
    .results-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    .results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .results-item {
        text-align: center;
        flex: 1;
    }
    .results-title {
        font-size: 2em;
        padding-bottom: 0.5em;
        line-height: 1em;
    }
    .results-item-center {
        padding: 0 5em;
    }
    .return-warning {
        padding: 1em 0;
        font-size: 2em;
    }
    .return-warning-animated {
        display: inline-block;
    }
</style>
<Time bind:time={now} updateInterval={500} />
<div class="page-wrapper">
    {#if showLoader || state === STATE_LOADING}
        <div class="ui active dimmer">
            <div class="ui text loader">Loading</div>
        </div>
    {/if}

    <div class="header">
        <div class="header-left"><i>{team0}</i> vs <i>{team1}</i> <b>Map Bans</b></div>
        <div class="header-right">Best of {boSize}</div>
    </div>
    <div class="indicator">
        {#if state === STATE_BAN_SELF}
            <div class="indicator-primary green">YOUR TURN - BAN MAP</div>
        {/if}
        {#if state === STATE_CHOOSE_SIDE}
            <div class="indicator-primary green">YOUR TURN - CHOOSE SIDE</div>
        {/if}
        {#if state === STATE_CHOOSE_SELF}
            <div class="indicator-primary green">YOUR TURN - PICK MAP</div>
        {/if}
        {#if state === STATE_BAN_OTHER}
            <div class="indicator-primary red">WAITING FOR OTHER TEAM</div>
        {/if}
        {#if state === STATE_DONE}
            <div class="indicator-primary green">MAPS BANS COMPLETE</div>
        {/if}
        <!-- Bug in chrome causes blank screen with animated indicators
        {#if state === STATE_BAN_SELF || state === STATE_CHOOSE_SIDE}
            <div class="indicator-primary green" in:scale={TURN_IN_TRANSITION} out:scale={TURN_OUT_TRANSITION}>YOUR TURN</div>
        {/if}
        {#if state === STATE_BAN_SELF || state === STATE_CHOOSE_SIDE}
            <span class="indicator-primary-placeholder">YOUR TURN</span>
        {/if}
        {#if state === STATE_BAN_OTHER}
            <div class="indicator-primary red" in:scale={TURN_IN_TRANSITION} out:scale={TURN_OUT_TRANSITION}>WAITING FOR OTHER TEAM</div>
        {/if}
        {#if state === STATE_BAN_OTHER}
            <span class="indicator-primary-placeholder">WAITING FOR OTHER TEAM</span>
        {/if}
        {#if state === STATE_DONE}
            <div class="indicator-primary green" in:scale={TURN_IN_TRANSITION} out:scale={TURN_OUT_TRANSITION}>MAPS BANS COMPLETE</div>
        {/if}
        {#if state === STATE_DONE}
            <span class="indicator-primary-placeholder">MAP BANS COMPLETE</span>
        {/if}
        -->
    </div>
    {#if state !== STATE_DONE}
        <div class="map-cards">
            {#each Object.entries(maps) as map}
                <MapCard
                        mapName={map[1]}
                        map={map[0]}
                        state={
                        mapStatus[map[0]] != null ? mapStatus[map[0]] : (state === STATE_BAN_SELF ? STATE_MAP_READY : (state === STATE_CHOOSE_SELF ? STATE_MAP_READY_CHOOSE : STATE_MAP_NOT_READY))
                        }
                        onClick={() => confirmBanMap(map[0], map[1])} />
            {/each}
        </div>
    {/if}
    {#if ((currentlyBanningMap != null && currentlyBanningMapName != null) || state === STATE_CHOOSE_SIDE) && !showLoader}
        <div class="modal-background" in:fade={{duration: 200}}></div>
    {/if}
    {#if currentlyBanningMap != null && currentlyBanningMapName != null && !showLoader}
        <div class="modal" style="width: 15em" in:scale={MODAL_IN_TRANSITION} out:scale={MODAL_OUT_TRANSITION}>
            <div class="modal-title">
                {#if state === STATE_BAN_SELF}
                    Ban
                {:else}
                    Pick
                {/if}
                {currentlyBanningMapName}?
            </div>
            <div class="modal-body">
                Are you sure you want to {#if state === STATE_BAN_SELF}ban{:else}pick{/if} {currentlyBanningMapName}?
                <div class="modal-actions">
                    <div class="modal-action" on:click={() => banMap(currentlyBanningMap)}>
                        {#if state === STATE_BAN_SELF}BAN{:else}PICK{/if} MAP
                    </div>
                    <div class="modal-action" on:click={cancelBanMap}>CANCEL</div>
                </div>
            </div>
        </div>
    {/if}
    {#if state === STATE_CHOOSE_SIDE && (currentlyChoosingSide == null || currentlyChoosingSideName == null)}
        <div class="modal" in:scale={MODAL_IN_TRANSITION} out:scale={MODAL_OUT_TRANSITION}>
            <div class="modal-title">
                {currentlyPickingForMapName}: Choose a side
            </div>
            <div class="modal-body">
                <div style="padding-bottom: 1em">Please choose a side for {currentlyPickingForMapName}:</div>

                <SideCard side="t" onClick={() => confirmChooseSide("t", "Terrorists")} clickable={true} />
                <SideCard side="ct" onClick={() => confirmChooseSide("ct", "Counter-Terrorists")} clickable={true} />
            </div>
        </div>
    {/if}
    {#if currentlyChoosingSide != null && currentlyChoosingSideName != null && !showLoader}
        <div class="modal" style="width: 20em" in:scale={MODAL_IN_TRANSITION} out:scale={MODAL_OUT_TRANSITION}>
            <div class="modal-title">
                Choose {currentlyChoosingSideName}?
            </div>
            <div class="modal-body">
                Are you sure you want to choose side: {currentlyChoosingSideName}?
                <div class="modal-actions">
                    <div class="modal-action" on:click={() => chooseSide(currentlyChoosingSide)}>CHOOSE SIDE</div>
                    <div class="modal-action" on:click={cancelChooseSide}>CANCEL</div>
                </div>
            </div>
        </div>
    {/if}
    {#if state === STATE_DONE}
        <div class="finished">
            <div class="results-wrapper">
                {#each banResults as banResult}
                    <div class="results">
                        <div class="results-item">
                            <div class="results-title">{team0}</div>
                            <SideCard side={banResult.team0Side} clickable={false} />
                        </div>
                        <div class="results-item results-item-center">
                            <div class="results-title">Map</div>
                            <MapCard
                                    mapName={maps[banResult.chosenMap]}
                                    map={banResult.chosenMap}
                                    state={STATE_MAP_CHOSEN} />
                        </div>
                        <div class="results-item">
                            <div class="results-title">{team1}</div>
                            <SideCard side={banResult.team1Side} clickable={false} />
                        </div>
                    </div>
                {/each}
            </div>
            <div class="return-warning">
                {#if secondsUntilNextGame > 0}
                    PLEASE RETURN TO CS:GO, GAME BEGINS IN
                    <span class="return-warning-animated" bind:this={secondsElement}>{secondsUntilNextGame} seconds</span>
                {:else}
                    PLEASE RETURN TO CS:GO, THE GAME HAS ALREADY STARTED
                {/if}
            </div>
        </div>
    {/if}
</div>
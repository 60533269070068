<script>
    import {
        STATE_MAP_READY,
        STATE_MAP_NOT_READY,
        STATE_MAP_BANNED_BY_OTHER,
        STATE_MAP_BANNED_BY_SELF,
        STATE_MAP_CHOSEN,
        STATE_MAP_BANNED_BY_TEAM_0,
        STATE_MAP_BANNED_BY_TEAM_1,
        STATE_MAP_CHOSEN_BY_TEAM_0,
        STATE_MAP_CHOSEN_BY_TEAM_1,
        STATE_MAP_CHOSEN_BY_SELF,
        STATE_MAP_CHOSEN_BY_OTHER,
        STATE_MAP_READY_CHOOSE
    } from "./MapCardState";

    export let mapName = "Dust";
    export let map = "de_dust2";

    export let state = 0;

    export let onClick = null;

    let banText;
    $: if(state === STATE_MAP_READY) {
        banText = "Click to ban";
    } else if(state === STATE_MAP_NOT_READY) {
        banText = "...";
    } else if(state === STATE_MAP_BANNED_BY_OTHER) {
        banText = "Banned by other team";
    } else if(state === STATE_MAP_BANNED_BY_SELF) {
        banText = "Banned by your team";
    } else if(state === STATE_MAP_CHOSEN) {
        banText = "Picked map";
    } else if(state === STATE_MAP_BANNED_BY_TEAM_0) {
        banText = "Banned by team 1";
    } else if(state === STATE_MAP_BANNED_BY_TEAM_1) {
        banText = "Banned by team 2";
    } else if(state === STATE_MAP_CHOSEN_BY_TEAM_0) {
        banText = "Picked by team 1";
    } else if(state === STATE_MAP_CHOSEN_BY_TEAM_1) {
        banText = "Picked by team 2";
    } else if(state === STATE_MAP_CHOSEN_BY_OTHER) {
        banText = "Picked by other team";
    } else if(state === STATE_MAP_CHOSEN_BY_SELF) {
        banText = "Picked by your team";
    } else if(state === STATE_MAP_READY_CHOOSE) {
        banText = "Click to pick";
    }

    function cardBackgroundColor(state) {
        if(state === STATE_MAP_BANNED_BY_OTHER
                || state === STATE_MAP_BANNED_BY_SELF
                || state === STATE_MAP_BANNED_BY_TEAM_0
                || state === STATE_MAP_BANNED_BY_TEAM_1) {
            return "255, 0, 0";
        } else if(state === STATE_MAP_CHOSEN
                || state === STATE_MAP_CHOSEN_BY_OTHER
                || state === STATE_MAP_CHOSEN_BY_SELF
                || state === STATE_MAP_CHOSEN_BY_TEAM_0
                || state === STATE_MAP_CHOSEN_BY_TEAM_1) {
            return "0, 255, 0";
        } else {
            return "0, 0, 0";
        }
    }
</script>
<style>
    .map-card {
        position: relative;
        display: inline-block;
        margin: 1em;
        opacity: 0.7;
        transition: 0.5s opacity, 0.5s box-shadow;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
        color: white;
        height: 15em;
        width: 25em;
        line-height: 2em;
    }
    .map-card:hover, .map-chosen {
        opacity: 1;
    }
    .map-card:hover:not(.map-chosen) {
        cursor: pointer;
        box-shadow: 0 0 10px 5px rgba(255, 0, 0, 0.5);
    }
    .map-card:hover:not(.map-chosen) > .map-card-screenshot {
        box-shadow: inset 0 0 0 5px red;
        padding: 5px;
    }
    .map-chosen, .map-ready-choose.map-card:hover {
        box-shadow: 0 0 10px 5px rgba(0, 255, 0, 0.5);
    }
    .map-chosen > .map-card-screenshot, .map-ready-choose.map-card:hover > .map-card-screenshot {
        box-shadow: inset 0 0 0 5px rgb(0,255,0);
        padding: 5px;
    }
    .map-card-screenshot {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 15em;
        width: 25em;
        background-position: 50% 50%;
        background-size: cover;
        text-align: center;
        transition: 0.5s box-shadow, 0.5s padding;
    }
    .map-card-screenshot > img {
        flex-grow: 1;
        height: 100%;
        padding: 1em;
    }
    .map-card-title, .map-card-secondary {
        text-align: center;
    }
    .map-card-title {
        font-size: 1.5em;
    }
    .map-card-secondary {
        font-size: 0.8em;
        margin-bottom: 0.5em;
        transition: 0.5s color, 0.5s margin, 0.5s background;
        padding: 0 0.5em;
    }
    .map-banned.map-card {
        box-shadow: 0 0 10px 5px rgba(255, 0, 0, 0.5);
        pointer-events: none;
    }
    .map-card-shim {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='red' stroke-width='2'/><path d='M0 0 L100 100 ' stroke='red' stroke-width='2'/></svg>");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%, auto;
        pointer-events: none;
        transition: 0.5s opacity;
        opacity: 0;
    }
    .map-banned .map-card-shim {
        opacity: 1;
    }
    .map-banned .map-card-screenshot {
        box-shadow: inset 0 0 0 5px red;
        padding: 5px;
    }
    .map-banned .map-card-secondary {
        color: white;
        background-color: red;
        margin-top: 0.5em;
    }

    .map-not-ready.map-card {
        pointer-events: none;
    }
</style>
<div class="map-card"
     class:map-banned={state === STATE_MAP_BANNED_BY_SELF || state === STATE_MAP_BANNED_BY_OTHER || state === STATE_MAP_BANNED_BY_TEAM_0 || state === STATE_MAP_BANNED_BY_TEAM_1}
     class:map-not-ready={state === STATE_MAP_NOT_READY}
     class:map-ready-choose={state === STATE_MAP_READY_CHOOSE}
     class:map-chosen={state === STATE_MAP_CHOSEN || state === STATE_MAP_CHOSEN_BY_OTHER || state === STATE_MAP_CHOSEN_BY_SELF || state === STATE_MAP_CHOSEN_BY_TEAM_0 || state === STATE_MAP_CHOSEN_BY_TEAM_1}
     on:click={() => {if(onClick != null) onClick();}}>
    <div class="map-card-screenshot"
         style="background-image: linear-gradient(to top, rgba({cardBackgroundColor(state)}, 0.5), rgba({cardBackgroundColor(state)}, 0)), url('/images/map-bans/map_icons/screenshots/1080p/{map}.png');">
        <img src="/images/map-bans/map_icons/map_icon_{map}.svg" draggable="false"/>
        <div class="map-card-title">{mapName}</div>
        <div class="map-card-secondary">
            {banText}
        </div>
        <div class="map-card-shim"></div>
    </div>
</div>
<script>
    import { Router, Route, links } from "svelte-routing";
    import PrivacyPolicy from "./PrivacyPolicy.svelte";
    import TermsOfService from "./TermsOfService.svelte";
    import {slide} from "svelte/transition";

    export let nestedPath;
    let section = "";
    $: splitSection = nestedPath.split("/");
    $: {
        if(splitSection.length > 0) {
            section = splitSection[0];
        } else {
            section = "";
        }
    }
</script>

<style>
    .page-content {
        padding-top: 5em;
    }
</style>

<div class="page-content" in:slide>
    <div class="ui container">
        <h1 class="ui inverted header">Legal Stuff</h1>
        <div class="ui inverted top attached tabular menu" use:links>
            <a class="item" href="privacy" class:active={section === "privacy"}>
                Privacy Policy
            </a>
            <a class="item" href="terms-of-service" class:active={section === "terms-of-service"}>
                Terms of Service
            </a>
        </div>
        <div class="ui inverted bottom attached segment">
            <Router>
                <Route path="privacy" component="{PrivacyPolicy}" />
                <Route path="terms-of-service" component="{TermsOfService}" />
            </Router>
        </div>
    </div>
</div>
<script>
    import Modal from "../../components/fomantic/Modal.svelte";
    import {tick} from "svelte";
    import {slide} from "svelte/transition";
    import {apiPost} from "../../api";

    let modal;
    let authCode = "";
    let ok = false;

    export async function show() {
        await tick();
        modal.show();
    }

    let saving = false;
    export async function save() {
        if(saving) return;

        saving = true;
        try {
            let response = await apiPost(`/profile/link-steam`, {
                authCode: authCode
            });
            if(response.success) {
                ok = true;
            } else {
                jQuery('body').toast({
                    class: 'error',
                    title: 'Failed to verify identity: ' + response.error,
                    message: response.error,
                    displayTime: 10000
                });
            }
        } finally {
            saving = false;
        }
    }
</script>
<style>
    .content, .content input {
        text-align: center;
    }
    .content input {
        letter-spacing: 0.5em;
    }
</style>
<Modal size="mini" inverted={true} dimmerInverted={true} canHide={false} bind:this={modal}>
    <div class="header">Verify account identity</div>
    <div class="content">
        {#if !ok}
            <div out:slide>
                <p>Please enter your four digit verification code:</p>
                <div class="ui massive input" class:disabled={saving}>
                    <input type="text"
                           placeholder="XXXX"
                           maxlength="4"
                           oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                           bind:value={authCode}
                           on:keydown={e => {if(e.keyCode === 13) save()}}>
                </div>
            </div>
        {:else}
            <div class="ui success message" in:slide>
                <div class="header">
                    Verification successful!
                </div>
                <p>Please close this tab and join the game server again.</p>
            </div>
        {/if}
    </div>
    {#if !ok}
        <div class="actions" out:slide>
            <div class="ui primary approve button" class:loading={saving} on:click={save}>Submit</div>
        </div>
    {/if}
</Modal>

<script>
    import { link } from 'svelte-routing';

    export let icon;
    export let text;
    export let href;

    export let element = null;
</script>

<style>
    .card > .header {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        /* Light theme */
        /* color: black !important; */
        opacity: 0.5;
        text-decoration: none !important;
        text-align: center;
    }
    .card {
        /* Light theme */
        /* background-color: #f3f3f3 !important; */
        min-height: 15em !important;
        min-width: 15em !important;
    }
</style>

<a class="card" href={href} use:link bind:this={element}>
    <h2 class="ui inverted icon header">
        <i class="icon {icon}"></i>
        <div class="content">
            {text}
        </div>
    </h2>
</a>

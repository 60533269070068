<script>
    import {gameTypes, tournamentTypes} from "./teams";
    import MemberList from "./MemberList.svelte";
    import { Link } from 'svelte-routing';

    export let onDisband;
    export let onLeave;
    export let team;

    let tournament = null;
    $: if(team != null && $tournamentTypes != null) {
        tournament = $tournamentTypes.find(t => t.id === team.team.tournament);
    }
    let game = null;
    $: if(tournament != null && $gameTypes != null) {
        game = $gameTypes.find(g => g.internalId === tournament.game);
    }
</script>

<style>
    .waitlist-banner {
        text-align: center;
    }
    .success-banner {
        background-color: #022500 !important;
    }
    .warning-banner {
        background-color: #232300 !important;
    }
</style>

<div class="extra content">
<!--        <img class="right floated mini ui image" src="/images/avatar/large/elliot.jpg">-->
    {#if team.team.disqualified}
        <div class="ui top attached label red waitlist-banner">NOT ELIGIBLE</div>
    {:else if team.onWaitlist}
        <div class="ui top attached label red waitlist-banner">ON WAITLIST</div>
    {/if}
    <div class="header">
        {team.team.name}
    </div>
    {#if tournament != null && game != null}
        <div class="meta">
            {tournament.name} - {game.displayName}
        </div>
    {/if}
</div>
<div class="content">
    <h5 class="ui header">Members</h5>
    <MemberList showDetails={false} isSubstitute={false} memberList={team.members}/>
</div>
{#if team.substitutes.length > 0}
    <div class="content">
        <h5 class="ui header">Substitutes</h5>
        <MemberList showDetails={false} isSubstitute={true} memberList={team.substitutes}/>
    </div>
{/if}
{#if !team.team.disqualified}
    <div class="extra content">
        {#if team.owned}
            <div class="ui red button" on:click={onDisband}>Disband</div>
            <Link to={`/panel/teams/edit/${team.team.ulid}`}>
                <div class="ui button">Edit</div>
            </Link>
        {:else}
            <div class="ui red button" on:click={onLeave}>Leave</div>
        {/if}
    </div>
{/if}
{#if team.issues.length > 0}
    <div class="ui bottom attached inverted yellow message warning-banner">
        <i class="x icon"></i>Your team cannot participate in the next match as {team.issues[0]}!
    </div>
{:else}
    <div class="ui bottom attached inverted success message success-banner">
        <i class="icon check"></i>Your team is ready for it's next match!
    </div>
{/if}

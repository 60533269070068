<style>
    .grid.main {
        height: 100%;
    }
    h1.huge.header {
        font-size: 5em;
    }
</style>

<div class="ui middle aligned center aligned grid main">
    <div class="column">
        <h1 class="ui huge header inverted">
            404 Not Found
            <div class="sub header">Sorry, this page doesn't seem to exist!</div>
        </h1>
    </div>
</div>

<script>
    import LoginPanel from "./LoginPanel.svelte";
    import queryString from "query-string";
    import { BASE_URL, apiPost } from "../api";
    import { navigate } from "svelte-routing";

    let discordLoggingIn = true;
    function doDiscordLogin() {
        if(!discordLoggingIn) {
            discordLoggingIn = true;
            let query = queryString.parse(window.location.search);
            let newLocation = `${BASE_URL}/auth/oauth?stage=discord&host=${window.location.host}`;
            if(query.redirect != null) {
                newLocation += `&redirect=${query.redirect}`;
            }
            window.location.href = newLocation;
        }
    }

    async function checkExistingAuth() {
        let response = await apiPost("/auth/verify");
        if(response.success && response.data.profile != null) {
            // Already logged in
            navigate("/panel", { replace: true });
        }

        discordLoggingIn = false;
    }

    checkExistingAuth();
</script>

<style>
    .discord-login.button {
        background-color: #7289DA;
        color: white;
    }
</style>

<LoginPanel title="Account Login">
    <div slot="content">
        <h5 class="ui">Please login with your Discord account to continue:</h5>
        <div class="ui fluid huge submit button discord-login"
             class:loading={discordLoggingIn}
             on:click={doDiscordLogin}>
            <i class="fab fa-discord icon"></i>
            Login with Discord
        </div>
    </div>

    <div slot="extra">
        <!-- TODO WREL -> OEF -->
        <p>A Discord account is required to participate in OEF events. If you are having trouble signing in, please message a moderator on our <a href="http://ontarioesports.org/discord">Discord server</a> for assistance.</p>
        <p>We only use your Discord account for identification purposes and do not have access to your server list/messages.</p>
    </div>
</LoginPanel>
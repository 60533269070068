<script>
    import {repositionTethers} from "../../common";
    import Tether from "../../components/tether/Tether.svelte";
    import TeamCard from "./TeamCard.svelte";
    import IconCard from "../../components/IconCard.svelte"
    import {apiGet, apiPost} from "../../api";
    import { scale } from 'svelte/transition';
    import {onDestroy} from 'svelte';

    export let profileTab = null;

    let currentlyDisbandingTeam;
    let disbandModal;
    $: if(disbandModal != null) {
        let jqDisbandModal = jQuery(disbandModal);
        jqDisbandModal.modal({inverted: true});
        onDestroy(() => jqDisbandModal.modal('destroy'));
    }

    let currentlyLeavingTeam;
    let leaveModal;
    $: if(leaveModal != null) {
        let jqLeaveModal = jQuery(leaveModal);
        jqLeaveModal.modal({inverted: true});
        onDestroy(() => jqLeaveModal.modal('destroy'));
    }

    let addTeamBtn = null;

    let loading = false;
    let teams = [];

    async function getTeams() {
        loading = true;
        try {
            let response = await apiGet("/teams");
            if(!response.success) {
                jQuery('body').toast({
                    class: 'error',
                    title: 'Failed to get team list',
                    message: response.error
                });
                return;
            }

            let newTeams = [];
            for(let team of response.data.ownedTeams) {
                newTeams.push({...team, owned: true});
            }
            for(let team of response.data.memberTeams) {
                newTeams.push({...team, owned: false});
            }
            teams = newTeams;
        } finally {
            loading = false;
        }
    }

    async function askDisbandTeam(team) {
        currentlyDisbandingTeam = team;
        jQuery(disbandModal).modal('show');
    }

    async function askLeaveTeam(team) {
        currentlyLeavingTeam = team;
        jQuery(leaveModal).modal('show');
    }

    async function disbandTeam() {
        jQuery(disbandModal).modal('hide');

        loading = true;
        try {
            let response = await apiPost(`/teams/${currentlyDisbandingTeam.team.ulid}/disband`);
            if(!response.success) {
                jQuery('body').toast({
                    class: 'error',
                    title: 'Failed to disband team',
                    message: response.error
                });
                return;
            }

            jQuery('body').toast({
                class: 'success',
                title: "Team disbanded",
                message: `${currentlyDisbandingTeam.team.name} has been disbanded!`
            });
        } finally {
            loading = false;
        }

        getTeams();
    }

    async function leaveTeam() {
        jQuery(leaveModal).modal('hide');

        loading = true;
        try {
            let response = await apiPost(`/teams/${currentlyLeavingTeam.team.ulid}/leave`);
            if(!response.success) {
                jQuery('body').toast({
                    class: 'error',
                    title: 'Failed to leave team',
                    message: response.error
                });
                return;
            }

            jQuery('body').toast({
                class: 'success',
                title: "Left team",
                message: `You have left ${currentlyLeavingTeam.team.name}!`
            });
        } finally {
            loading = false;
        }

        getTeams();
    }

    async function getProfile() {
        try {
            let response = await apiGet('/profile');
            if(!response.success) {
                return null;
            }

            return response.data;
        } catch(e) {
            console.error("Could not get user profile:", e);
        }
    }

    let showUpdateProfileHint = false;
    async function checkProfileHint() {
        const profile = await getProfile();
        if(!profile.valid) {
            showUpdateProfileHint = true;
        }
    }

    getTeams();
    checkProfileHint();
</script>

<style>
    .tutorial-floating {
        text-align: center;
        animation: float 2s ease-in-out infinite;

        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
    }

    @keyframes float {
        0% {
            transform: translateY(-1px);
        }
        50% {
            transform: translateY(-6px);
        }
        100% {
            transform: translateY(-1px);
        }
    }

    .disqualified {
        opacity: 0.3;
        pointer-events: none;
    }
</style>

<div class="ui basic segment container">
    <h1 class="ui inverted header">My Teams</h1>
</div>
<div class="ui basic segment inverted container" class:loading={loading}>
    <div class="ui inverted cards">
        {#each teams as team, index (team.team.ulid)}
            <div class="card"
                 class:disqualified={team.team.disqualified}
                 in:scale|local="{{delay: index * 200}}"
                 out:scale|local
                 on:introend={repositionTethers}
                 on:outroend={repositionTethers}>
                <TeamCard team={team} onDisband={() => askDisbandTeam(team)} onLeave={() => askLeaveTeam(team)}/>
            </div>
        {/each}

        <IconCard icon="fas fa-plus" text="Create team" href="/panel/teams/edit" bind:element={addTeamBtn}/>
    </div>
</div>

<!-- TUTORIALS! -->
{#if teams.length <= 0 && !loading}
    <Tether target={addTeamBtn} attachment="top center" targetAttachment="bottom center">
        <div class="ui pointing label tutorial-floating blue">
            Click the button above and create<br/>a team to join the OEF Launch Tournament!
        </div>
    </Tether>
{/if}

{#if teams.length >= 1 && showUpdateProfileHint}
    <Tether target={profileTab} attachment="top center" targetAttachment="bottom center">
        <div class="ui pointing label tutorial-floating red">
            Your profile is incomplete! It must be completed<br/>
            before you can participate in any OEF tournaments.<br/>
            Click on this button to go complete it.
        </div>
    </Tether>
{/if}

<!-- MODALS -->
<div class="ui mini inverted modal" bind:this={disbandModal}>
    <div class="header">Disband team?</div>
    <div class="content">
        {#if currentlyDisbandingTeam != null}
            <p>
                Are you sure you want to disband {currentlyDisbandingTeam.team.name}? This action cannot be undone!
            </p>
            {#if !currentlyDisbandingTeam.team.onWaitlist}
                <p style="color:red;font-weight:bold">
                    By disbanding your team you are also giving up your team's spot in the tournament.
                    If the tournament is full, you may not be able to find a spot in the tournament again if you change your mind.
                </p>
            {/if}
        {/if}
    </div>
    <div class="actions">
        <div class="ui red button" on:click={disbandTeam}>Disband</div>
        <div class="ui cancel button">Cancel</div>
    </div>
</div>

<div class="ui mini inverted modal" bind:this={leaveModal}>
    <div class="header">Leave team?</div>
    <div class="content">
        {#if currentlyLeavingTeam != null}
            <p>Are you sure you want to leave {currentlyLeavingTeam.team.name}? You won't be able to join it again without an invite link!</p>
        {/if}
    </div>
    <div class="actions">
        <div class="ui red button" on:click={leaveTeam}>Leave</div>
        <div class="ui cancel button">Cancel</div>
    </div>
</div>

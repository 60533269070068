<script>
    // import Time from "../components/util/Time.svelte";
    // import humanizeDuration from "humanize-duration";
    // import SetInterval from "../components/util/SetInterval.svelte";
    import HomeCTALeft from "./HomeCTALeft.svelte";
    import JourneySVG from "./JourneySVG.svelte";
    import { links, link } from "svelte-routing";
    import ConfettiGenerator from "confetti-js";
    import ResizeObserver from 'resize-observer-polyfill';
    import {onDestroy} from 'svelte';
    import Id128 from 'id128';
    import {slide, fly} from 'svelte/transition';
    import {bestImage, webpSupported} from '../common';

    // const REGISTRATION_CLOSE_UNIX_MS = 1575072000 * 1000;
    // let now = 0;

    let ResizeObserverCompat = window.ResizeObserver || ResizeObserver;

    let confettiCanvas = null;
    let oldWidth = 0;
    let oldHeight = 0;
    let confetti = null;
    $: if(confettiCanvas != null && confetti == null) {
        function initConfetti(width, height) {
            console.debug("Initializing confetti...");
            destroyConfetti();
            confetti = new ConfettiGenerator({
                target: confettiCanvas.id,
                max: 100,
                size: 1,
                animate: true,
                props: ["circle","square","triangle","line"],
                colors: [[165,104,246],[230,61,135],[0,199,228],[253,214,126]],
                clock: 25,
                rotate: true,
                width: width,
                height: height
            });
            confettiCanvas.width = width;
            confettiCanvas.height = height;
            confetti.render();
        }

        function destroyConfetti() {
            if(confetti != null) {
                confetti.clear();
                confetti = null;
            }
        }

        let observer = new ResizeObserverCompat((a) => {
            if(a.length <= 0) return;

            const { width, height } = a[0].contentRect;
            let changed = false;
            if(width !== oldWidth) {
                oldWidth = width;
                changed = true;
            }
            if(height !== oldHeight) {
                oldHeight = height;
                changed = true;
            }

            if(changed) initConfetti(width, height);
        });
        observer.observe(confettiCanvas);
        onDestroy(() => {
            destroyConfetti();
            observer.disconnect();
        });
    }

    // Force start play BG video (sometimes will just not autoplay?)
    let bgVideo = null;
    $: if(bgVideo != null) bgVideo.play();
</script>

<!--<Time bind:time={now} />-->

<style>
    .home {
        font-family: 'Roboto', sans-serif;
        color: white;
        width: 100%;
    }
    .home .ui.header {
        color: white;
    }
    p {
        font-size: 15pt;
    }
    .cta-top {
        width: 100%;
        padding-top: 0 !important;
    }

    .cta-top > .container {
        position: relative;
        padding: 1em 0;
    }

    .cta-side-image {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 80%;
    }

    .cta-side-image > img {
        height: 100%;
        margin-bottom: -500px;
    }

    .cta-video-bg > video {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        right: 0;
        bottom: -300px;
        transform: translate(calc((100% - 100vw) / 2), -3rem);
        pointer-events: none;
        opacity: 0.3;
        overflow: hidden;
    }

    .cta-video-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
        top: 0;
    }

    .details-section {
        position: relative;
        padding: 5rem 0;
        font-size: 35pt;
    }
    .details-section h1.ui.header {
        font-size: 3rem;
    }
    .footer-section {
        padding: 2rem 0;
        font-size: 20pt;
    }
    .footer-section p {
        font-size: 10pt;
        color: grey;
    }
    .dates-section {
        text-align: center;
        background-color: #db2828;
        padding: 3rem 0;
    }
    .join-section {
        text-align: center;
        background-color: #1678c2;
        padding: 3rem 0;
    }

    .dark-section {
        background-color: #101010;
        color: white;
    }
    .dark-section .ui.header{
        color: white;
    }
    .dark-section .ui.list .item .content {
        color: white;
    }

    .light-section {
        background-color: white;
        color: black;
    }
    .light-section .ui.header{
        color: black;
    }

    .discord-section {
        padding: 3rem 0;
    }
    .discord-widget {
        box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.75);
        background: #202225;
        border-radius: 7px; /* Pro gamer move to remove white areas in shadow */
        width: 100%;
    }

    .about-section {
        text-align: left;
        padding: 3rem 0;
    }

    .center-links {
        font-size: 10pt;
        color: grey;
    }

    .center-links a {
        color: grey;
    }
    .social-icons {
        padding-bottom: 0.5rem;
    }
    .social-icons > a {
        transition: 0.25s opacity;
    }
    .social-icons > a:hover {
        opacity: 0.8;
    }

    .ui.discord.button {
        background: #7289DA;
        color: white;
    }

    .dates-header {
        font-weight: normal;
    }

    .confetti-canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .stats-box {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        font-size: 1.1rem;
    }
    .stats-box .stat {
        padding-bottom: 0.2rem;
    }
    .stats-button-wrapper {
        text-align: center;
    }
    .stats-button-wrapper > a {
        margin-bottom: 0 !important;
    }

    .about-right-img {
        box-shadow: 6px 6px 5px black;
    }

    /** TODO Hide CS:GO character when page too small **/
    /** TODO Reencode video **/

    @media only screen and (max-width: 750px) {
        .cta-side-image {
            display: none;
        }
        .cta-video-bg > video {
            top: 0;
            bottom: initial;
        }
    }
</style>

<div class="home">
    <div class="cta-top ui inverted vertical masthead center aligned segment">
        <div class="cta-video-bg">
            <video autoplay muted loop playsinline bind:this={bgVideo}>
                <source src="https://oef.s3.wasabisys.com/web/videos/ctf-bg.mp4" type="video/mp4">
            </video>
        </div>
        <div class="ui container">
            <div class="ui left aligned stackable grid container">
                <div class="row">
                    <div class="six wide column computer only">
                        <HomeCTALeft />
                    </div>
                    <div class="eight wide column tablet only">
                        <HomeCTALeft />
                    </div>
                    <div class="eight wide column mobile only">
                        <HomeCTALeft mobile={true}/>
                    </div>
                    <div class="six wide five wide computer right floated stats column">
                        <div class="stats-box" in:slide={{duration: 500, delay: 500}}>
                            <h3>Tournament specifications:</h3>
                            {#each [
                                "<b>No entry fee</b>",
                                "<b>$100 CAD guaranteed prize pool</b>",
                                "2nd place wins Steam Keys",
                                "Major-like format",
                                "Competitive, intense, but quick matches to <b>fit into your schedule</b>",
                                "All matches are streamed",
                                "<b>Finals are casted</b>",
                                "Registration ends <b>November 30th.</b>"
                            ] as stat, i}
                                <div class="stat" in:fly={{x: 50, delay: 700 + i * 100, duration: 500}}>
<!--                                    <i class="mini right circle middle top icon"></i>-->
                                    <b>-</b> {@html stat}
                                </div>
                            {/each}
                            <div class="stats-button-wrapper" in:slide={{delay: 1700}}>
                                <a class="ui grey tertiary button" href="/tourney-specs" use:link>
                                    More details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div class="cta-side-image">-->
<!--                <img src="/images/csgo-char.png" alt="CS:GO character"/>-->
<!--            </div>-->
        </div>
    </div>
    <div class="details-section">
        <canvas id={Id128.Ulid.generate().toCanonical()} class="confetti-canvas" bind:this={confettiCanvas}></canvas>
        <div class="ui container">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="eight wide column">
                        <h1 class="ui header">CELEBRATE THE LAUNCH OF OEF WITH US!</h1>
                        <p>
                            Join our FREE CS:GO Wingman tournament and celebrate the launch of the Ontario eSports Federation with us.
                            OEF strives to bring the professional eSports experience to every high school in Ontario.
                            Register today to test your skill against hundreds of other players across the province, the best team will get $100 in cash!
                        </p>
                    </div>
                    <div class="six wide right floated column">
                        <img src="/images/LOGO-C-TRANS.min.png" class="ui large image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="discord-section light-section">
        <div class="ui container">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="six wide computer eight wide tablet column">
                        <iframe class="discord-widget"
                                src="https://discordapp.com/widget?id=564871689922543647&theme=dark"
                                height="500"
                                allowtransparency="true"
                                frameborder="0"></iframe>
                    </div>
                    <div class="eight wide right floated column">
                        <h1 class="ui header">CHECK OUT OUR DISCORD</h1>
                        <p>
                            Come chat with us on our Discord server after you've registered for the tournament!
                            Find a team or create your own and recruit members.
                            Stay up to date on match results, news, upcoming tournaments and other exciting new opportunities.
                            Get reminders about important dates and events.
                            <i>You must be in our Discord server to participate in tournaments.</i>
                        </p>
                        <a class="ui huge discord button" target="_blank" href="/discord">
                            <i class="fab fa-discord icon"></i>
                            JOIN DISCORD SERVER
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-section dark-section">

        <div class="ui container">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide mobile sixteen wide tablet eight wide computer column">
                        <!-- TODO EDIT TEXT --->
                        <h1 class="ui header">WHAT IS OEF?</h1>
                        <p>
                            OEF (Ontario eSports Federation) is a non-profit organization led by eSports enthusiasts across Ontario aiming to provide a professional, competitive gaming environment for high school students.
                            OEF empowers students to exhibit their mechanical and cognitive skills against like-minded students from across Ontario and provides a stepping stone for young amateurs to grow their skills and get noticed in the eSports world.
                            We help provide the first step in making your passion for gaming become a reality!
                        </p>

                        <h1 class="ui header">Our platform provides:</h1>
                        <div class="ui large animated list">
                            {#each [
                            "High-grade tournaments with exceptional production quality",
                            "Large prize pools for player incentive",
                            "OEFLink: The platform that allows players to easily and efficiently manage everything related to tournaments",
                            "All tournaments streamed and shoutcasted live"
                            ] as feat}
                                <div class="item">
                                    <i class="large right triangle middle aligned icon"></i>
                                    <div class="content">
                                        {feat}
                                    </div>
                                </div>
                            {/each}
                        </div>
                    </div>
                    <div class="six wide computer only right floated column">
<!--                        <h1 class="ui center aligned header">THE OEF PRO PATH</h1>-->
<!--                        <JourneySVG/>-->
                        <img class="ui large image about-right-img" src={bestImage($webpSupported, "/images/journey-bg.min.jpg")}/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="join-section">
        <div class="ui text container">
            <h1 class="ui header">ENTER YOUR FIRST TOURNAMENT NOW!</h1>
            <p>Take your first step into pro eSports and join the OEF Launch Tournament. Register today to reserve your spot!</p>
            <a class="ui inverted large button centered" href="/auth/login" use:link>JOIN TOURNAMENT</a>
        </div>
    </div>

    <div class="dates-section">
        <div class="ui text container">
            <h3 class="ui header dates-body">
                REGISTRATION CLOSES ON <b>NOVEMBER 30TH</b>
<!--                {humanizeDuration(REGISTRATION_CLOSE_UNIX_MS - now, {-->
<!--                largest: 2,-->
<!--                conjunction: ' and ',-->
<!--                serialComma: false,-->
<!--                units: ['y', 'mo', 'w', 'd', 'h', 'm', 's']-->
<!--                }).toUpperCase()}-->
            </h3>
        </div>
    </div>

    <div class="footer-section">
        <div class="ui container">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <!-- Logo -->
                    <div class="four wide center aligned column mobile only">
                        <img src="/images/LOGO-C-TRANS.min.png" class="ui inline small image">
                    </div>
                    <div class="four wide column tablet only">
                        <img src="/images/LOGO-C-TRANS.min.png" class="ui small image">
                    </div>
                    <div class="four wide column computer only">
                        <img src="/images/LOGO-C-TRANS.min.png" class="ui small image">
                    </div>

                    <div class="center-links eight wide center aligned column" use:links>
                        <div class="social-icons">
                            <a href="/discord" target="_blank"><i class="large discord icon"></i></a>
                            <a href="https://www.youtube.com/channel/UC2Of4D5oikCpY9etpB93YDQ" target="_blank"><i class="large youtube icon"></i></a>
                            <a href="https://www.twitch.tv/ontarioesports" target="_blank"><i class="large twitch icon"></i></a>
                            <a href="https://www.instagram.com/ontarioesportsfed/" target="_blank"><i class="large instagram icon"></i></a>
                        </div>
                        <div class="legal-icons">
                            <a href="/legal/privacy">Privacy Policy</a>
                            |
                            <a href="/legal/terms-of-service">Terms of Service</a>
                        </div>
                    </div>

                    <!-- Copyright stuff -->
                    <div class="four wide center aligned right floated column mobile only">
                        <p>&copy; Copyright {new Date().getFullYear()} OEF</p>
                        <p>Waterloo, Ontario</p>
                    </div>
                    <div class="four wide right aligned floated column tablet only">
                        <p>&copy; Copyright {new Date().getFullYear()} OEF</p>
                        <p>Waterloo, Ontario</p>
                    </div>
                    <div class="four wide right aligned floated column computer only">
                        <p>&copy; Copyright {new Date().getFullYear()} OEF</p>
                        <p>Waterloo, Ontario</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
